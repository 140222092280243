@use '../../utils' as *;

/*----------------------------------------*/
/*  4.30 Widget css
/*----------------------------------------*/

.bd-sticky__top {
	position: -webkit-sticky;
	position: sticky;
	top: 120px;
	z-index: 2;
}
.widget-sidebar {
	z-index: 5;
	padding: 30px;
	border: 1px solid var(--bd-border-primary);
	@include border-radius(6px);
}
.guide__widget-box {
	@include border-radius(6px);
	background: var(--bd-bg-6);
	overflow: hidden;
	padding: 30px;
}
.widget-details {
	ul {
		li {
			list-style: none;
			&:not(:last-child) {
				margin-bottom: 12px;
				padding-bottom: 12px;
				border-bottom: 1px solid var(--bd-border-primary);
			}
			a {
				font-size: 18px;
				&:hover {
					color: var(--bd-primary);
				}
			}
		}
	}
} 
.color-box-inner {
	.color-box {
		width: 100%;
		height: 100px;
		@include border-radius(6px);
	}
}
.color-box-inner {
	.title {
		font-size: 14px;
	}
}

.elements__divider {
	margin: 50px 0;
}

.color-box-title {
	font-size: 14px;
	font-weight: 600;
	text-transform: uppercase;
	letter-spacing: 0.5px;
	opacity: 0.5;
	margin-bottom: 0;
}