
@use '../utils' as *;

/*---------------------------------
	1.3 Default Spacing SCSS
---------------------------------*/

// custom margin 
.section-space {
	padding-top: 100px;
	padding-bottom: 100px;
	@media #{$md} {
		padding-top: 80px;
		padding-bottom: 80px;
	}
	@media #{$sm,$xs} {
		padding-top: 65px;
		padding-bottom: 65px;
	}
}
.section-space-medium {
	padding-top: 80px;
	padding-bottom: 80px;
	@media #{$md} {
		padding-top: 60px;
		padding-bottom: 60px;
	}
	@media #{$sm,$xs} {
		padding-top: 50px;
		padding-bottom: 50px;
	}
}
.section-space-small {
	padding-top: 60px;
	padding-bottom: 60px;
	@media #{$sm,$xs} {
		padding-top: 50px;
		padding-bottom: 50px;
	}
}
.section-space-small-bottom {
	padding-bottom: 60px;
	@media #{$sm,$xs} {
		padding-bottom: 50px;
	}
}
.section-space-top {
	padding-top: 100px;
	@media #{$md} {
		padding-top: 80px;
	}
	@media #{$sm,$xs} {
		padding-top: 65px;
	}
}
.section-space-top-xxl {
	padding-top: 200px;
}
.section-space-bottom {
	padding-bottom: 100px;
	@media #{$md} {
		padding-bottom: 80px;
	}
	@media #{$sm,$xs} {

		padding-bottom: 65px;
	}
}
.section-space-bottom-xxl {
	padding-bottom: 200px;
}
.section-title-space {
	margin-bottom: 40px;
}
.btn__wrapper {
    margin-top: 40px;
	@media #{$xs, $sm} {
		margin-top: 30px;
	}
}
// Footer space
.footer-space-top {
	padding-top: 100px;
	@media #{$md} {
		padding-top: 800px;
	}
	@media #{$sm,$xs} {
		padding-top: 65px;
	}
}
// Cta space
.cta__space-1 {
	padding: 20px 0;
	@media #{$xs ,$sm, $md} {
		padding: 50px 0;
	}
}