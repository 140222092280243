@use "../utils" as *;

/*----------------------------------------*/
/*  2.6 Breadcrumb
/*----------------------------------------*/

.bd-breadcrumb {
  position: relative;
  z-index: 2;
  min-height: 550px;
  @media #{$sm,$xs} {
    min-height: 360px;
  }
  &-bg {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-repeat: no-repeat;
    background-size: cover;
    &::before {
      position: absolute;
      content: '';
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      opacity: 0.9;
      background: linear-gradient(180deg, #001A3D 0%, rgba(0, 26, 61, 0.40) 100%);
    }
  }
  &-content {
    padding-top: 80px;
    @media #{$sm,$xs} {
      padding-top: 50px;
    }
  }
  &-title {
    color: var(--bd-white);
    font-weight: var(--bd-fw-sbold);
    margin-bottom: 20px;
  }

  &-list {
    display: flex;
    justify-content: center;
    gap: 28px;
    color: var(--bd-white);

    span {
      position: relative;

      a {
        &:hover {
          color: var(--bd-primary);
        }
      }

      i {
        margin-inline-end: 10px;

        @include rtl {
          margin-inline-start: 10px;
        }
      }

      &:not(:last-child) {
        &::before {
          position: absolute;
          content: "";
          height: 2px;
          width: 6px;
          border-radius: 40%;
          background-color: var(--bd-white);
          inset-inline-end: -15px;
          top: 50%;
        }
      }
    }
  }

  &-area {
    &.style-two {
      padding-top: 30px;
      padding-bottom: 30px;

      .bd-breadcrumb-title {
        margin-bottom: 10px;
      }

      .bd-breadcrumb-content {
        padding-top: 20px;
        padding-bottom: 20px;
      }
    }
  }
}

.bd-breadcrumb-banner-area {
  min-height: 720px;
}

.breadcrumb-banner-bg {
  &::before {
    position: absolute;
    content: '';
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    opacity: 0.5;
    background: var(--bd-secondary);
  }
}



// Video Breadcrumb
.bd-video-breadcrumb-area {
  height: 600px;
}
.breadcrumb {
  &-video {
    &-bg {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: linear-gradient(90deg, #000C1C 31.15%, rgba(0, 12, 28, 0) 99.17%);
      opacity: 0.6;
      z-index: 1;
    }
  }
  &-video {
    position: absolute;
    top: 0px;
    left: 0;
    right: 0;
    bottom: 0;
    max-height: 600px;
    video {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}
.video-search-box {
  position: relative;
  z-index: 5;
  margin: 0 auto;
  padding-top: 280px;
  padding-bottom: 280px;
}