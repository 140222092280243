@use '../../utils' as *;

/*----------------------------------------*/
/*  3.6.1 Meanmenu css
/*----------------------------------------*/

.mean-container {
	margin-bottom: 35px;
}

.mean-container .mean-bar {
	padding: 0;
	min-height: auto;
	background: none;
	float: left;
	width: 100%;
	position: relative;
	padding: 4px 0;
	min-height: 42px;
	z-index: 999999;
}

.mean-container .mean-bar,
.mean-container .mean-bar * {
	-webkit-box-sizing: content-box;
	-moz-box-sizing: content-box;
	box-sizing: content-box;
}

.mean-container a.meanmenu-reveal {
	width: 22px;
	height: 22px;
	padding: 13px 13px 11px 13px;
	position: absolute;
	top: 0;
	right: 0;
	cursor: pointer;
	color: var(--bd-white);
	text-decoration: none;
	font-size: 16px;
	text-indent: -9999em;
	line-height: 22px;
	font-size: 1px;
	font-weight: 700;
	display: none !important;
}

.mean-container a.meanmenu-reveal span {
	display: block;
	background: var(--bd-white);
	height: 3px;
	margin-top: 3px;
}

.mean-container .mean-nav {
	background: none;
	margin-top: 0;
	float: left;
	width: 100%;
	margin-top: 35px;
}

.mean-container .mean-nav>ul {
	padding: 0;
	margin: 0;
	width: 100%;
	list-style-type: none;
	display: block !important;
}

.mean-container .mean-nav ul {
	padding: 0;
	margin: 0;
	width: 100%;
	list-style-type: none;

	&.mega-menu {
		position: static;
		height: 100%;
		width: 100%;
		background: transparent;
		z-index: 3;
		display: block;
		padding: 0 0;
		opacity: 1;
		visibility: visible;
		margin-right: 35px;
		transition: none;
	}
}

.mean-container {
	.mean-nav ul {
		li {
			position: relative;
			float: left;
			width: 100%;
			border-bottom: 1px solid var(--bd-border-secondary);
		}
	}
}


.mean-container .mean-nav ul li a,
.mean-container .mean-nav ul li span {
	display: block;
	float: left;
	width: 90%;
	padding: 10px 5%;
	margin: 0;
	text-align: left;
	text-decoration: none;
	text-transform: capitalize;
	width: 100%;
	padding: 10px 0;
	color: var(--bd-heading);
	border-top: 1px solid rgba(255, 255, 255, 0.079);
	font-size: 16px;
	line-height: 1.5;
	font-weight: 500;
}

.mean-container .mean-nav>ul>li:first-child>a {
	border-top: 0;
}

.mean-container .mean-nav ul li.dropdown-opened>a,
.mean-container .mean-nav ul li.dropdown-opened>span {
	color: var(--bd-primary);
}

.mean-container .mean-nav ul li a.mean-expand,
.mean-container .mean-nav ul li span.mean-expand {
	text-align: center;
	position: absolute;
	right: 0;
	top: 0;
	z-index: 2;
	font-weight: 700;
	background: transparent;
	border: none !important;
	font-size: 14px;
	margin-top: 5px;
	padding: 0 !important;
	border: 1px solid rgba(255, 255, 255, 0.079) !important;
	height: 30px;
	width: 30px;
	color: var(--bd-heading);
	line-height: 30px;
	top: 0;
	font-weight: 400;
}

.mean-container .mean-nav ul li.dropdown-opened>a.mean-expand.mean-clicked,
.mean-container .mean-nav ul li.dropdown-opened>span.mean-expand.mean-clicked {
	background: var(--bd-primary);
	color: var(--bd-white);
	border-color: transparent;
}

.mean-container .mean-nav ul li.dropdown-opened>a.mean-expand.mean-clicked i,
.mean-container .mean-nav ul li.dropdown-opened>span.mean-expand.mean-clicked i {
	color: var(--bd-white);
	-webkit-transform: rotate(90deg);
	-moz-transform: rotate(90deg);
	-ms-transform: rotate(90deg);
	-o-transform: rotate(90deg);
	transform: rotate(90deg);
}