@use '../../utils' as *;

/*----------------------------------------*/
/*  card css
/*----------------------------------------*/

.card {
	&-wrapper {
		background: var(--bd-flash-white);
		padding: 20px 20px;
		position: relative;
		border-radius: 4px 4px 0 0;
	}
	&-btn {
		.btn-style {
			border-radius: 0 0 4px 4px;
		}
	}
	&-icon {
		span {
			background: rgba(56, 96, 226, 0.1);
			width: 60px;
			height: 60px;
			border-radius: 50%;
			display: flex;
			text-align: center;
			justify-content: center;
			align-items: center;
			color: var(--bd-primary);
			font-size: 32px;
		}
	}
	&-sub-title {
		color: var(--bd-text-body);
	}
	&-desc {
		display: inline-block;
		color: var(--bd-text-body);
	}
}

.price-increase {
	color: var(--bd-bg-success);
	font-weight: 600;
	font-size: 12px;
	line-height: 1;
}
.price-decrease {
	color: var(--bd-bg-danger);
	font-weight: 600;
	font-size: 12px;
	line-height: 1;
}

.dropdown {
	&-item {
		color: var(--bd-text-body);
		font-size: 16px;
	}
	&-btn {
		width: 30px;
		height: 30px;
		border-radius: 50%;
		display: flex;
		text-align: center;
		justify-content: center;
		align-content: center;
		align-items: center;
		transition: all 0.3s linear;
		&:hover {
			background-color: rgba(56, 96, 226, 0.1);
		}
		i {
			font-size: 20px;
			color: var(--bd-black);
		}
	}
	&-menu{
		&.show {
			display: block;
			position: absolute;
			inset: auto !important;
			top: 0 !important;
			right: 0 !important;
		}
	}
}



.tour {
    &-thumb {
        &-chnage {
            position: relative;
        }
        &-edit {
            input {
                display: none;
            }
            label {
				padding: 10px 10px;
				font-size: 22px;
				font-weight: 500;
				color: var(--bd-heading);
				background-color: transparent;
				border: 1px solid var(--bd-border-primary);
				border-top: none;
				text-align: center;
				width: 100%;
				cursor: pointer;
				transition: all 0.3s linear;
				border-radius: 0 0 8px 8px;
				&:hover {
					color: var(--bd-white);
					background-color: var(--bd-primary);
				}
            }
        }

        &-preview {
            width: 100%;
            height: 560px;
            position: relative;
            &-box {
				width: 100%;
				height: 560px;
				background-size: cover;
				background-repeat: no-repeat;
				background-position: center;
				border-radius: 8px 8px 0 0;
            }
        }
    }
}

.tour-details-location {
	.input-box-select {
		width: 100%;
		height: 56px;
		border: none;
		border: 1px solid var(--bd-border-primary);
		background-color: transparent;
		display: flex;
		align-items: center;
		color: var(--bd-heading);
		&:focus {
			border-color: var(--bd-primary);
		}
	}
}

.tour-input-wrapp {
	background-color: rgba(220, 220, 220, 0.20);
}
.recent-activity-content {
	.table> tbody > tr {
		transition: all 0.3s linear;
		&:hover {
			background: var(--bd-white);
			box-shadow: 0px 4px 70px rgba(0, 0, 0, 0.15);
		}
	}
}

// dropzone customized
.dropzone {
	border: 1px solid var(--bd-border-secondary);
	background: white;
	padding: 20px 20px;
	border-radius: 8px;
}
.dropzone .dz-preview .dz-image {
	border-radius: 8px;
	overflow: hidden;
	width: 175px;
	height: 175px;
	position: relative;
	display: block;
	z-index: 5;
}
.dropzone .dz-preview .dz-image img {
	display: block;
	width: 175px;
	height: 175px;
	object-fit: cover;
}