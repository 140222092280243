@use './mixins' as *;

%include-bg{
	@include background();
}

%bd-transition-color{
	@include bd-transition(color);
}

%bd-transition-transform{
	@include bd-transition(transform);
}

%bd-transition-border-color{
	@include bd-transition(border-color);
}

%bd-transition-bg-color{
	@include bd-transition(background-color);
}

%bd-transition-fz{
	@include bd-transition(font-size);
}

// Transition 
%bd-transition-3{
	@include bd-transition();
}

%bd-transition-5{
	@include bd-transition(opacity, 0.5s, ease-in-out, 0.2s);
}

// Font
%bd-ff-roboto{
	font-family: var(--bd-ff-roboto);
}

%bd-ff-jost{
	font-family: var(--bd-ff-jost);
}

%bd-ff-fontawesome{
	font-family: var(--bd-ff-fontawesome);
}

// Transform
%translateY1_2{
	@include transform(translateY(-50%));
}
%translateX1_2{
	@include transform(translateX(-50%));
}
%translate1_2{
	@include transform(translate(-50%, -50%));
}

// Bg thumbnails
%bg-thumb{
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;

}

// Bg theme color
.bg-theme-primary {
	background-color: var(--bd-primary);
}
.bg-theme-secondary {
	background-color: var(--bd-secondary);
}
.bg-theme-tertiary {
	background-color: var(--bd-tertiary);
}
.bg-theme-quaternary {
	background: var(--bd-quaternary);
}