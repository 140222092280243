@use '../../utils' as *;

/*----------------------------------------*/
/*  3.5.5 Header Style 1
/*----------------------------------------*/
// Header top
.header {
	&-top {
		&-area {
			padding: 10px 0;
		}
		&-main {
			display: flex;
			align-items: center;
			justify-content: space-between;
			@media #{$xs, $sm} {
				justify-content: start;
			}
		}
		&-left-item {
			&:first-child {
				margin-right: 24px;
			}
			span {
				margin-right: 6px;
				color: var(--bd-tertiary);
			}
			a {
				color: var(--bd-white);
				transition: all 0.3s ease-in-out;
				&:hover {
					color: var(--bd-tertiary);
				}
			}
		  }
	}
	&-social {
		display: flex;
		gap: 10px;
		ul {
			display: flex;
			align-items: center;
			gap: 10px;
			li {
				list-style: none;
				a {
					color: var(--bd-white);
				}
			}
		}
		p {
			margin-bottom: 0;
		}
	}
}
.topbar {
	&-social {
		position: relative;
		margin-left: 25px;
		&:not(:first-child) {
			&:before {
				position: absolute;
				content: "";
				height: 13px;
				width: 1px;
				background: rgba(255, 255, 255, 0.3);
				left: -20px;
				top: 50%;
				transform: translateY(-50%);
			}
		}
		ul {
			display: flex;
			gap: 10px;
			flex-wrap: wrap;
			li {
				list-style: none;
				a {
					color: var(--bd-white);
					font-size: 20px;
					transition: all 0.3s ease-in-out;
					&:hover {
						color: var(--bd-tertiary);
					}
				}
			}
		}
	}
}

// currency toggle
.header {
	&-currency {
		&-item {
			position: relative;
			padding-right: 15px;
			& > span {
				position: relative;
				display: inline-block;
				font-weight: 500;
				font-size: 16px;
				color: var(--bd-white);
				padding-right: 20px;
				transition: all 0.1s linear;
				z-index: 1;
				&:hover {
					cursor: pointer;
					color: var(--bd-white);
					&::before {
						opacity: 1;
					}
				}
				&::before {
					position: absolute;
					content: "";
					left: 50%;
					top: 50%;
					transform: translate(-50%, -50%);
					background-color: var(--bd-primary);
					width: 75px;
					height: 35px;
					z-index: -1;
					opacity: 0;
					border-radius: 20px;
					transition: all 0.2s linear;
				  }
				&::after {
					position: absolute;
					content: "\f107";
					font-family: var(--bd-ff-fontawesome);
					margin-left: 7px;
				}
			}
			ul {
				position: absolute;
				top: 130%;
				left: 0;
				z-index: 111;
				background-color: var(--bd-white);
				padding: 15px 15px;
				box-shadow: 0px 1px 3px rgba(3, 4, 28, 0.12);
				visibility: hidden;
				opacity: 0;
				min-width: max-content;
				-webkit-transition: all 0.2s 0s ease-out;
				-moz-transition: all 0.2s 0s ease-out;
				-ms-transition: all 0.2s 0s ease-out;
				-o-transition: all 0.2s 0s ease-out;
				transition: all 0.2s 0s ease-out;
				&.lang-list-open {
					visibility: visible;
					opacity: 1;
				}
				li {
					list-style: none;
					a {
						color: var(--bd-heading);
						font-size: 15px;
						&:hover {
							color: var(--bd-primary);
						}
					}
				}
			}
			&.style-two {
				padding-right: 0;
				span {
					border: 1px solid var(--bd-border-tertiary);
					border-radius: 30px;
					padding: 2px 30px 2px 10px;
					background-color: transparent;
					color: var(--bd-heading);
					&::before {
						display: none;
					}
					&:hover {
						color: var(--bd-white);
						background-color: var(--bd-primary);
						border-color: var(--bd-primary);
					}
				}
			}
		}
	}
}


// language toggle
.header {
	&-language {
		&-item {
			position: relative;
			padding-right: 15px;
			& > span {
				position: relative;
				display: inline-block;
				font-weight: 500;
				font-size: 16px;
				color: var(--bd-heading);
				padding-right: 20px;
				transition: all 0.3s linear;
				z-index: 1;
				border: 1px solid var(--bd-border-tertiary);
				border-radius: 30px;
				padding: 2px 30px 2px 10px;
				background-color: transparent;
				&:hover {
					cursor: pointer;
					color: var(--bd-white);
					background-color: var(--bd-primary);
					border-color: var(--bd-primary);
				}
				&::after {
					position: absolute;
					content: "\f107";
					font-family: var(--bd-ff-fontawesome);
					margin-left: 7px;
				}
			}
			ul {
				position: absolute;
				top: 130%;
				left: 0;
				z-index: 111;
				background-color: var(--bd-white);
				padding: 15px 15px;
				box-shadow: 0px 1px 3px rgba(3, 4, 28, 0.12);
				visibility: hidden;
				opacity: 0;
				min-width: max-content;
				-webkit-transition: all 0.2s 0s ease-out;
				-moz-transition: all 0.2s 0s ease-out;
				-ms-transition: all 0.2s 0s ease-out;
				-o-transition: all 0.2s 0s ease-out;
				transition: all 0.2s 0s ease-out;
				&.lang-list-open {
					visibility: visible;
					opacity: 1;
				}
				li {
					list-style: none;
					a {
						color: var(--bd-heading);
						font-size: 15px;
						&:hover {
							color: var(--bd-primary);
						}
					}
				}
			}
		}
	}
}

.bd-sticky {
	.header-currency-item ul {
		top: 62px;
	}
	.header-language-item ul {
		top: 62px;
	}
}