@use '../../utils' as *;

/*----------------------------------------*/
/*  3.2.1 Blog tag css
/*----------------------------------------*/

.blog-tag span a {
    font-size: 16px;
    font-weight: var(--bd-fw-regular);
    text-transform: capitalize;
    padding: 3px 11px;
	border-radius: 40px;
	color: var(--bd-heading);
    background-color: var(--bd-white);
	@extend %bd-transition-3;
    &:hover {
        color: var(--bd-white);
        background-color: var(--bd-primary);
    }
}
.blog-meta-item {
    display: inline-flex;
    gap: 0 6px;
    align-items: center;
    font-size: 16px;
    font-weight: var(--bd-fw-regular);
    &:not(:last-child) {
        margin-right: 25px;
        @media #{$xs} {
            margin-right: 10px;
        }
    }
    a {
        &:hover {
            color: var(--bd-primary);
        }
    }
    .meta-icon {
        color: var(--bd-primary);
    }
    .meta-text {
        color: var(--bd-text-meta);
    }
    .meta-author {
        color: var(--bd-text-tag);
    }
}