@use '../../utils' as *;

/*----------------------------------------*/
/*  4.19 Price css
/*----------------------------------------*/

.pricing {
    &-wrapper {
        &.pricing-style {
            background: #F9F9F9;
            border: 1.5px solid rgba(1, 6, 20, 0.16);
            box-shadow: 0px 4px 70px rgba(0, 0, 0, 0.17);
            border-radius: 16px;
            transition: all 0.3s ease-in-out;
            &:hover {
                border: 1.5px solid var(--bd-primary);
            }
            .pricing-thumb {
                position: relative;
                &::before {
                    position: absolute;
                    content: "";
                    left: 0;
                    top: 0;
                    width: 100%;
                    height: 100%;
                    background-color: var(--bd-primary);
                    opacity: 0.6;
                    border-radius: 16px;
                }
                img {
                    border: 1.5px solid rgba(1, 6, 20, 0.16);
                    border-radius: 16px;
                }
            }
            .pricing-tag {
                position: absolute;
                top: 30px;
                left: 0;
                background-color: var(--bd-tertiary);
                padding: 5px 25px;
                font-size: 20px;
                font-weight: var(--bd-fw-sbold);
                border-radius: 0px 4px 4px 0px;
            }
            .pricing-package-price {
                position: absolute;
                inset-inline-start: 50%;
                transform: translateX(-50%);
                bottom: 50px;
            }
            .pricing-content {
                padding: 55px 30px 40px 30px;
            }
            .pricing-content-list {
                margin-bottom: 30px;
                ul {
                    li {
                        position: relative;
                        margin-left: 30px;
                        &:not(:last-child) {
                            margin-bottom: 25px;
                        }
                        &::before {
                            position: absolute;
                            content: "\f00c";
                            font-family: "Font Awesome 6 Pro";
                            left: -30px;
                            top: 50%;
                            width: 22px;
                            height: 22px;
                            background-color: var(--bd-primary);
                            transform: translateY(-50%);
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            border-radius: 50%;
                            font-size: 12px;
                            color: var(--bd-white);
                        }
                    }
                }
            }
            &.active-pricing {
                border: 1.5px solid var(--bd-primary);
            }
        }
    }
}

.pricing {
    &-item {
        border: 2px solid var(--bd-border-secondary);
        padding: 35px 30px 40px;
        border-radius: 10px;
        transition: .3s;
        &:hover{
            border-color: var(--bd-primary);
        }
        @media #{$md,$xs}{
            padding: 35px 20px 40px;
        }
        &.active {
            border-color: var(--bd-primary);
        }
    }
    &-content {
        transition: .3s;
        .pricing-title {
            margin-bottom: 15px;
        }
        p {
            margin-bottom: 22px;
        }
        .pricing-amount {
            margin-bottom: 23px;
            line-height: 1.1;
            .dollar-sign {
                font-size: 24px;
                transform: translateY(-15px) translateX(5px);
                display: inline-block;
                font-weight: 400;
                @media #{$md}{
                    font-size: 20px;
                }
                @media #{$sm,$xs}{
                    font-size: 18px;
                }
            }
            .duration {
                font-size: 14px;
                color: rgba(255, 255, 255, 0.50);
                transform: translateX(-7px);
                display: inline-block;
                font-weight: 400;
            }
        }
    }
    &-feature {
        margin-top: 27px;
        .pricing-list {
            li {
                list-style: none;
                display: flex;
                align-items: center;
                gap: 0 13px;

                &:not(:last-child) {
                    margin-bottom: 25px;
                }
                i {
                    color: var(--bd-white);
                    background: var(--bd-primary);
                    width: 22px;
                    height: 22px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border-radius: 50%;
                    font-size: 12px;
                    font-weight: 600;
                    transition: .5s;
                }
            }
        }
    }
}
