@use '../../utils' as *;
/*----------------------------------------*/
/*  3.1.2 banner Style 2
/*----------------------------------------*/

.banner-two-content {
	padding-top: 235px;
	padding-bottom: 410px;
	text-align: center;
	@media #{$lg} {
		padding-top: 190px;
		padding-bottom: 310px;
	}
	@media #{$xs} {
		padding-top: 90px;
	}
	.banner-description {
		color: var(--bd-text-tertiary);
	}
}

// Hero style
.banner-slider-mask {
	position: relative;
	z-index: 5;

	&::before {
		position: absolute;
		content: "";
		left: 0;
		bottom: -1px;
		background-image: url(../images/shapes/banner-2-mask.png);
		z-index: 1;
		width: 100%;
		height: 58px;
		background-repeat: no-repeat;
		background-position: bottom;
		background-size: contain;
		z-index: 10;
	}
}

.banner-booking-two {
	position: absolute;
	width: 100%;
	bottom: 285px;
	@media #{$lg} {
		bottom: 180px;
	}
	@media #{$md} {
		bottom: 190px;
	}
	@media #{$sm,$xs} {
		bottom: 90px;
	}
	.style-two {
		@media #{$xl,$lg} {
			width: 100%;
		}
	}
}

.banner-two-image {
	position: absolute;
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	z-index: -2;
	transition: transform 7000ms ease, opacity 1500ms ease-in, -webkit-transform 7000ms ease;
	&::before {
		position: absolute;
		content: "";
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		background: var(--bd-secondary);
		opacity: 0.6;
	}
}

.banner-two-active .swiper-slide-active {
	& .banner-subtitle,
	& .banner-title,
	& .banner-description {
		animation-fill-mode: both;
		animation-name: fadeInUp;
	}
}

.banner-two-active {
	& .swiper-slide-active {
		& .banner-title {
			animation-delay: 1.2s;
			animation-duration: 0.7s;
		}

		& .banner-description {
			animation-delay: 1.3s;
			animation-duration: 0.8s;
		}

		& .banner-two-image {
			transform: scale(1.15);
		}
	}
}