@use '../../utils' as *;

/*----------------------------------------*/
/*  4.18 List
/*----------------------------------------*/

// search 
.bd-search {
	$self: &;
	&-popup {
		width: 100%;
		font-size: 64px;
		padding-bottom: 17px;

		&-area {
			position: fixed;
			top: 0;
			left: 0;
			right: 0;
			display: flex;
			z-index: 99999;
			min-height: 300px;
			align-items: center;
			background-color: var(--bd-flash-white);
			transform: translateY(-10%);
			opacity: 0;
			visibility: hidden;
			transition: all 0.3s ease-in;

			&#{$self}-opened {
				opacity: 1;
				visibility: visible;
				transform: translateY(0%);
			}
		}
	}

	&-form {
		position: relative;
		width: 100%;
		border-bottom: 2px solid var(--bd-border-primary);
		padding-bottom: 20px;
	}

	&-input {
		background-color: transparent;
		display: flex;
		align-items: center;
		input {
			width: 100%;
			color: var(--bd-placeholder);
			height: auto;
			background-color: transparent;
			font-size: 32px;
			margin: 0 55px;
			border: none;
			outline: none;
			&::placeholder {
				color: var(--bd-heading);
				opacity: .5;
				font-size: 32px;
			}

			&[type="search"]::-webkit-search-cancel-button {
				-webkit-appearance: none;
			}
		}
	}

	&-submit {
		position: absolute;
		inset-inline-start: 0;
		top: 40%;
		transform: translateY(-50%);

		button {
			color: var(--bd-primary);
			font-size: 35px;
			position: relative;
			transform: translateY(-10px);

			&:hover {
				color: var(--bd-tertiary);
			}
		}
	}

	&-close {
		&-btn {
			position: absolute;
			inset-inline-end: 0;
			top: 40%;
			transform: translateY(-50%);

			button {
				font-size: 50px;
				color: var(--bd-primary);

				&:hover {
					transform: rotate(90deg);
					color: var(--bd-tertiary);
				}
			}
		}
	}

	&-overlay {
		position: fixed;
		opacity: 0;
		visibility: hidden;
		width: 100%;
		height: 100%;
		top: 0;
		bottom: 0;
		z-index: 9998;
		background-color: rgba(0, 0, 0, 0.6);
		backdrop-filter: blur(10px);
		-moz-backdrop-filter: blur(10px);
		transition-delay: .3s;
		transition: all .3s ease-in-out;

		&#{$self}-opened {
			opacity: 1;
			visibility: visible;
		}
	}
}