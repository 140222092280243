@use '../utils' as *;

/*----------------------------------------*/
/*  2.1 Back to top
/*----------------------------------------*/

.backtotop-wrap {
	position: fixed;
	bottom: 30px;
	right: 30px;
	height: 46px;
	width: 46px;
	cursor: pointer;
	display: block;
	border-radius: 50px;
	z-index: 100;
	opacity: 0;
	visibility: hidden;
	@include transform(translateY(20px));
	-webkit-transition: all 400ms linear;
	-o-transition: all 400ms linear;
	transition: all 400ms linear;
	background: var(--bd-primary);
	@media #{$xs} {
		bottom: 20px;
		right: 20px;
	} 
	&.active-progress {
		opacity: 1;
		visibility: visible;
		@include transform(translateY(0px));
	}
	&::after {
		position: absolute;
		font-family: var(--bd-ff-fontawesome);
		content: "\f062";
		text-align: center;
		line-height: 46px;
		font-size: 16px;
		font-weight: 400;
		color: var(--bd-white);
		left: 0;
		top: 0;
		height: 46px;
		width: 46px;
		cursor: pointer;
		display: block;
		z-index: 1;
		-webkit-transition: all 400ms linear;
		-o-transition: all 400ms linear;
		transition: all 400ms linear;
	}
	svg {
		path {
			fill: none;
		}
		&.backtotop-circle {
			path {
				stroke: #ccc;
				stroke-width: 0;
				-webkit-box-sizing: border-box;
				box-sizing: border-box;
				-webkit-transition: all 400ms linear;
				-o-transition: all 400ms linear;
				transition: all 400ms linear;
			}
		}
	}
}


