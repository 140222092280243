@use "../../utils" as *;

/*----------------------------------------*/
/*  3.2.4 Recent Post css
/*----------------------------------------*/

.rc {
  &__post {
    gap: 20px;
    &:not(:last-child) {
      margin-bottom: 25px;
    }
    &-thumb {
      & img {
        width: 80px;
        height: 80px;
        mix-blend-mode: luminosity;
      }
    }
    &-title {
      font-size: 18px;
      line-height: 1.3;
      font-weight: 500;
      letter-spacing: -0.2px;
      color: rgba(255, 255, 255, 0.8);
      margin-bottom: 2px;
      & a {
        &:hover {
          color: var(--bd-primary);
        }
      }
    }
  }
  &__meta {
    & span {
      font-size: 14px;
      position: relative;
      padding-left: 15px;
      &::before {
        position: absolute;
        content: "";
        height: 5px;
        width: 5px;
        background: rgba(124, 126, 130, 0.5);
        top: 50%;
        @extend %translateY1_2;
        left: 0;
      }
    }
  }
}
