@use '../utils' as *;

/*----------------------------------------*/
/*  2.9 Offcanvas
/*----------------------------------------*/

// Offcanvas style
.offcanvas{
    &-area {
        background: var(--bd-flash-white) none repeat scroll 0 0;
        position: fixed;
        right: 0;
        top: 0;
        width: 500px;
        height: 100%;
        transform: translateX(calc(100% + 80px));
        transition: transform 0.45s ease-in-out, opacity 0.45s ease-in-out;
        z-index: 999;
        overflow-y: scroll;
        overscroll-behavior-y: contain;
        scrollbar-width: none;
        border-inline-start: 1px solid var(--bd-border-primary);
        ::-webkit-scrollbar {
            display: none;
        }
        @media #{$xs}{
            width: 400px;
        }
        @media #{$xs}{
            width: 100%;
        }
        &.info-open {
            opacity: 1;
            @include transform(translateX(0));
        }
    }
    &-logo {
        a {
            img {
                width: 160px;
                @media #{$xs} {
                    width: 150px;
                }
                @media #{$xs} {
                    width: 140px;
                }
            }
        }
    }
    &-content {
        padding-bottom: 45px;
    }
    &-wrapper{
        position: relative;
        height: 100%;
        padding: 45px 45px;
        @media #{$xs}{
            padding: 30px 30px;
        }
    }
    &-top{
        padding-bottom: 20px;
        border-bottom: 1px solid var(--bd-border-primary);;
    }
    &-color{
        margin-bottom: 40px;
        & h5{
            font-size: 22px;
            margin-bottom: 15px;
        }
        & input{
            display: none;
        }
        & label{
            width: 100%;
            height: 60px;
            background-color: var(--bd-primary);
            &:hover{
                cursor: pointer;
            }
        }

        &-2{
            label{
                background-color: var(--bd-theme-2);
            }
        }
    }
    &-search{
        position: relative;
        button {
            position: absolute;
            top: 50%;
            inset-inline-start: 0;
            transform: translateY(-50%);
            font-size: 16px;
            color: var(--bd-secondary);
            &:hover {
                color: var(--bd-primary);
              }
        }
        input {
            width: 100%;
            height: 40px;
            line-height: 40px;
            padding: 0 25px;
            background: transparent;
            border: none;
            outline: none;
            border-bottom: 1px solid #D5D5D5;
            font-size: 18px;
            color: var(--bd-black);
            &::placeholder {
                color: var(--bd-placeholder);
            }
            &:focus{
                border-radius: 0;
            }
        }
    }
    &-title-meta {
        color: var(--bd-heading);
        margin-bottom: 17px;
    }
    &-text{
        & p{
            margin-bottom: 25px;
        }
    }
    &-social{
        margin-bottom: 40px;
        & ul{
            & li{
                display: inline-block;
                &:not(:last-child){
                    margin-right: 5px;
                }
                a {
                    @include flexbox();
                    align-items: center;
                    justify-content: center;
                    width: 36px;
                    height: 36px;
                    background: transparent;
                    color: var(--bd-black);
                    border: 1px solid var(--bd-border-secondary);
                    font-size: 16px;
                    @include border-radius(50%);
                    &:hover {
                        background: var(--bd-primary);
                        color: var(--bd-white);
                        border-color: var(--bd-primary);
                    }
                }
            }
        }
    }
    &-notification{
        &-icon{
            & a{
                font-size: 14px;
                color: var(--bd-white);
                position: relative;

                & .notification-count{
                    position: absolute;
                    top: -4px;
                    right: -13px;
                    display: inline-block;
                    width: 20px;
                    height: 20px;
                    line-height: 22px;
                    font-size: 12px;
                    font-weight: 600;
                    background-color: var(--bd-primary);
                    @include border-radius(50%);
                    color: var(--bd-white);
                    text-align: center;
                }
            }
        }
        &-text{
            & p {
                margin-bottom: 0;
                font-weight: 500;
                color: var(--bd-white);
                font-size: 14px;
                
                & .notification-number{
                    color: var(--bd-primary);
                }
                & a{
                    color: var(--bd-primary);
                }
            }
        }
    }
    &-overlay {
        background-color: rgba(0, 0, 0, 0.6);
        height: 100%;
        width: 100%;
        position: fixed;
        top: 0;
        z-index: 99;
        inset-inline-start: 0;
        opacity: 0;
        visibility: hidden;
        @extend %bd-transition-3;
        backdrop-filter: blur(10px);
    
        &:hover {
            cursor: pointer;
        }
        
        &.overlay-open {
            opacity: 1;
            visibility: visible;
        }
    }
    &-contact {
        ul {
            li {
                &:not(:last-child) {
                    margin-bottom: 20px;
                }
                a {
                    &:hover {
                        color: var(--bd-primary);
                        i {
                            background: var(--bd-primary);
                            color: var(--bd-white);
                            border-color: var(--bd-primary);
                        }
                    }
                }
            }
        }
        &-icon {
            i {
                display: inline-block;
                width: 36px;
                height: 36px;
                display: flex;
                justify-content: center;
                text-align: center;
                align-items: center;
                border-radius: 50%;
                background: transparent;
                color: var(--bd-black);
                border: 1px solid var(--bd-border-secondary);
                -webkit-transition: all 0.3s ease-out 0s;
                -moz-transition: all 0.3s ease-out 0s;
                -ms-transition: all 0.3s ease-out 0s;
                -o-transition: all 0.3s ease-out 0s;
                transition: all 0.3s ease-out 0s;
            }
        }
    }
    &-btn {

        .bd-btn {
            border: 1px solid var(--bd-border-secondary);
            padding: 0 12px;
            &:hover {
                border-color: var(--bd-primary);
            }
        }
    }
}
.sidebar-toggle {
	cursor: pointer;
}
.offcanvas-slider-content {
	border: 1px solid var(--bd-border-primary);
	padding: 20px;
}
.offcanvas-navigation {
	display: flex;
	align-items: center;
	gap: 20px;
	button {
		svg {
            @extend %bd-transition-3;
			* {
				fill: var(--bd-white);

			}
		}
        &:hover {
            svg {
                * {
                    fill: var(--bd-primary);
    
                }
            }
        }
	}
}
.offcanvas-post-title {
    font-size: 26px;
    color: var(--bd-white);
    a {
        &:hover {
            color: var(--bd-primary);
        }
    }
}

// Close icon 
.offcanvas-close-icon {
	line-height: 1;
	color: var(--bd-secondary);
	.offcanvas-m-lines {
        position: relative;
        display: block;
        height: 12px;
        width: 33px;
	}
	&.animation--flip {
		.offcanvas-m-line {
			&.line--1 {
				top: 6px;
				transform: rotate(45deg);
			}
			&.line--3 {
				opacity: 0;
				opacity: 0;
			}
			&.line--2 {
				top: 6px;
				transform: rotate(-45deg);
			}
		}
	}
	.offcanvas-m-line {
		position: absolute;
		inset-inline-start: 0;
		height: 2px;
		width: 100%;
		background-color: currentColor;
		transition: top 0.15s ease, transform 0.3s ease;
		&.line--1 {
			top: 0;
		}
		&.line--2 {
			top: 5px;
		}
		&.line--3 {
			bottom: 0;
			bottom: 0;
		}
	}
	&:hover {
		.offcanvas-m-line {
			animation: qode-draw 0.8s cubic-bezier(0.44, 1.1, 0.53, 0.99) 1 forwards;
			animation: qode-draw 0.8s cubic-bezier(0.44, 1.1, 0.53, 0.99) 1 forwards;
			&:nth-of-type(2) {
				animation-delay: 0.1s;
			}
		}
	}
}
