@use "../../utils" as *;

/*----------------------------------------*/
/*  4.21 progress css
/*----------------------------------------*/

.single-progress-bar {
	display: flex;
	align-items: center;
	margin-bottom: 5px;
	gap: 15px;
	position: relative;
}

.single-progress-bar .rating-text {
	color: var(--bd-gray);
	font-weight: var(--bd-fw-medium);
	flex-basis: 2%;
}

.single-progress-bar .progress {
	background-color: #F7F3FF;
}
.progress {
	width: 400px;
	border-radius: 0;
	height: 18px;
}
.progress .progress-bar.bg-1 {
	background-color: #07BB98;
}
.progress .progress-bar.bg-2 {
	background-color: #A5E562;
}
.progress .progress-bar.bg-3 {
	background-color: #DDEF08;
}
.progress .progress-bar.bg-4 {
	background-color: #FFC107;
}
.progress .progress-bar.bg-5 {
	background-color: #FF6125;
}

.single-progress-bar .value-text {
	color: var(--bd-heading);
	font-weight: var(--bd-fw-medium);
	width: 38px;
}
.single-progress-bar .number {
	color: var(--bd-gray);
	font-weight: var(--bd-fw-medium);
}