@use "../../utils" as *;

/*----------------------------------------*/
/*  4.9 tour css
/*----------------------------------------*/

.destinations-details-thumb {
	height: 412px;
	min-width: auto;
    border-radius: 8px;
    img {
        width: 100%;
        height: 100%;
        border-radius: 8px;
    }
}