@use "../utils" as *;

/*----------------------------------------*/
/*  2.4 Background
/*----------------------------------------*/

.white-bg {
  background-color: var(--bd-white);
}
.black-bg {
  background-color: var(--bd-black);
}

// Theme bg
.theme-bg {
  background-color: var(--bd-bg-primary);
}
.theme-bg-secondary {
  background-color: var(--bd-bg-secondary);
}
.theme-bg-light {
  background-color: rgba($color: #006CE4, $alpha: .10);
}
.warning-bg {
  background-color: var(--bd-tertiary);
}
.theme-bg-4 {
  background-color: var(--bd-bg-4);
}
.theme-bg-5 {
  background-color: var(--bd-bg-5);
}
.theme-bg-6 {
  background-color: var(--bd-bg-6);
}
.theme-bg-7 {
  background-color: var(--bd-bg-7);
}
.theme-bg-8 {
  background-color: var(--bd-bg-8);
}
.theme-bg-9 {
  background-color: var(--bd-bg-9);
}
.theme-bg0 {
  background-color: var(--bd-bg-primary0);
}

// Gradient color
.gradient-bg-1 {
	background: linear-gradient(180deg, rgba(28, 31, 57, 0) 17.61%, #0D1B2A 100%);
}
.gradient-bg-2 {
  background: linear-gradient(180deg, rgba(0, 108, 228, 0) 0%, rgba(0, 108, 228, 0.75) 100%);
}
.gradient-bg-3 {
  background: linear-gradient(89.77deg, rgba(0, 108, 228, 0.69) 50.02%, rgba(0, 26, 61, 0) 99.82%);
}
.gradient-bg-4 {
  background: linear-gradient(180deg, #FEC300 0%, rgba(254, 195, 0, 0) 100%);
}

// Background color
.dark-bg {
  background: var(--bd-black);
}

.flash-white {
  background-color: var(--bd-flash-white);
}

// Half Background
.half-bg::before {
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 55%;
	background-color: var(--bd-primary);
	content: "";
	z-index: -1;
}