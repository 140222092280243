@use '../utils' as *;

/*----------------------------------------*/
/*  2.2 Video
/*----------------------------------------*/

.video-bg {
	background-size: cover;
	background-repeat: no-repeat;
	min-height: 700px;
	display: flex;
	align-items: center;
	justify-content: center;
	// background-attachment: fixed;
    @media #{$sm,$xs} {
        min-height: 300px;
    }
    &::before {
        position: absolute;
        content: "";
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        background-color: var(--bd-bg-secondary);
        opacity: 0.6;
    }
}