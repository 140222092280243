@use "../utils" as *;

/*----------------------------------------*/
/*  2.2 forms
/*----------------------------------------*/

.form-input-box {
	&.has-icon {
		.form-input {
			position: relative;

			span {
				position: absolute;
				display: inline-block;
			}
		}

		&.icon-left {
			.form-input {

				input,
				textarea {
					padding-inline-start: 45px;
				}
			}
		}

		&.icon-right {
			.form-input {

				input,
				textarea {
					padding-inline-end: 45px;
				}
			}
		}
	}
}

.form-input-box {
	&.has-icon {
		&.icon-left {
			.form-input {
				span {
					color: var(--bd-placeholder);
					inset-inline-start: 20px;
					top: 22px;
				}
			}
		}

		&.icon-right {
			.form-input {
				span {
					color: var(--bd-placeholder);
					inset-inline-end: 20px;
					top: 22px;
				}
			}
		}
	}

	.form-input {
		span {
			display: none;
		}
	}
}

.form-input-box {
	&.label-hidden {
		label {
			display: none;
		}
	}
}

.form {
	&-input {
		position: relative;

		&-title {
			label {
				margin-bottom: 5px;
				text-transform: capitalize;
				font-size: 16px;
				text-align: left;
				display: block;

				span {
					color: var(--bd-bg-danger);
					margin-inline-start: 3px;
				}
			}
		}
	}

	&-input {
		textarea {
			height: 220px;
			padding: 22px 20px;
			resize: none;
		}
	}

	&-select {
		&.nice-select {
			height: 60px;
			width: 100%;
			background: rgba(255, 255, 255, 0.05);
			padding: 0 20px;
			display: flex;
			align-items: center;
			float: none;
			border: 1px solid rgba(255, 255, 255, 0.1);

			&.open {
				font-size: 16px;

				.list {
					@include transform(scale(1) translateY(0px));
					box-shadow: 0 0 0 1px rgb(56, 56, 56)
				}
			}

			.current {
				text-align: left;
				font-size: 16px;
				position: relative;
			}

			.list {
				transform-origin: center center;
				@include transform(scale(0.9) translateY(0px));
				width: 100%;
				padding: 10px 0;
				background: var(--bd-bg-2);
			}

			&::after {
				color: var(--bd-heading);
				font-size: 18px;
				inset-inline-end: 23px;
				width: 8px;
				height: 8px;
			}

			.option {
				font-size: 16px;

				&:hover {
					background-color: var(--bd-bg-primary);
				}
			}

			.option.focus {
				background-color: var(--bd-bg-primary);
			}

			.option.selected.focus {
				background-color: var(--bd-bg-primary);
			}
		}
	}
}

.form-input-box {
	&.from-flex {
		display: flex;
		gap: 0 10px;

		@media #{$xs} {
			flex-direction: column;
		}
		.form-input-title {
			width: 120px;
		}
	}
}

.form-input {
	position: relative;
	flex-grow: 14;
}

// Floating form
.floating-form-input {
	input {
		&:focus {
			~ {
				.floating-label {
					top: 0;
					inset-inline-start: 20px;
					font-size: 14px;
					font-weight: 400;
					color: var(--bd-black);
					background-color: var(--bd-white);
					padding: 0px 4px;
				}
			}
		}

		&:not(:focus) {
			&:valid {
				~ {
					.floating-label {
						top: 0;
						inset-inline-start: 20px;
						font-size: 16px;
						font-weight: 400;
						background-color: var(--bd-white);
						padding: 0px 4px;
					}
				}
			}
		}

		background-color: transparent;
	}

	.floating-label {
		position: absolute;
		pointer-events: none;
		inset-inline-start: 20px;
		top: 50%;
		transform: translateY(-50%);
		transition: 0.2s ease all;
		color: var(--bd-placeholder);
	}
	position: relative;
	textarea {
		height: 220px;
		padding: 22px 20px;
		resize: none;
		background: transparent;

		&:focus {
			~ {
				.floating-label-two {
					top: -12px;
					inset-inline-start: 15px;
					padding: 0px 4px;
					font-size: 14px;
					color: var(--bd-black);
					background-color: var(--bd-white);
				}
			}
		}

		&:not(:focus) {
			&:valid {
				~ {
					.floating-label-two {
						top: -12px;
						inset-inline-start: 20px;
						padding: 0px 4px;
					}
				}
			}
		}
	}

	.floating-label-two {
		position: absolute;
		pointer-events: none;
		inset-inline-start: 20px;
		top: 20px;
		transition: 0.2s ease all;
		background-color: var(--bd-white);
		color: var(--bd-placeholder);
	}
}

// Custom checkbox button
input[type="checkbox"] {
	display: none;

	&:checked {
		~ {
			label {
				&::before {
					-webkit-transform: scale(1);
					-moz-transform: scale(1);
					-ms-transform: scale(1);
					-o-transform: scale(1);
					transform: scale(1);
				}
			}
		}
	}

	~label {
		position: relative;
		padding-inline-start: 30px;

		&::after {
			position: absolute;
			content: '';
			top: 2px;
			left: 0;
			width: 18px;
			height: 18px;
			line-height: 16px;
			text-align: center;
			border: 1px solid #C3C7C9;
			z-index: -1;
			transition: all .2s linear;
		}

		&::before {
			position: absolute;
			content: url('../images/icons/check.svg');
			top: 2px;
			left: 0;
			width: 18px;
			height: 18px;
			line-height: 16px;
			text-align: center;
			visibility: hidden;
			opacity: 0;
			color: var(--bd-white);
			transition: all .2s linear;
		}

		&:hover {
			cursor: pointer;
		}
	}
}

// Custom radio button 
input[type="radio"] {
	opacity: 0;
	position: absolute;

	~label {
		position: relative;
		font-size: 15px;
		line-height: 25px;
		font-weight: 400;
		padding-inline-start: 28px;
		cursor: pointer;
		margin-bottom: 0;

		&::before {
			content: "";
			position: absolute;
			top: 3px;
			inset-inline-start: 0;
			width: 20px;
			height: 20px;
			background-color: transparent;
			border: 1px solid var(--bd-border-tertiary);
			border-radius: 2px;
			transition: all 0.3s;
		}

		&::after {
			content: " ";
			position: absolute;
			top: 9px;
			inset-inline-start: 2px;
			width: 10px;
			height: 5px;
			background-color: red;
			border-radius: 2px;
			transform: rotate(-45deg);
			opacity: 0;
			transition: all .3s;
		}
	}

	&:checked {
		~label {
			&::before {
				border-color: var(--bd-primary);
			}

			&::after {
				opacity: 1;
				background-color: var(--bd-primary);
			}
		}
	}
}

input[type="radio"] {
	~label {
		&::before {
			border-radius: 50%;
		}

		&::after {
			width: 12px;
			height: 12px;
			inset-inline-start: 4px;
			background: #fff;
			border-radius: 50%;
			top: 7px;
		}
	}
}

// Custom switch button 
.switch-checkbox {
	position: relative;
	display: inline-block;
	width: 38px;
	height: 22px;

	input {
		opacity: 0;
		width: 0;
		height: 0;
	}
}

.switch.round {
	border-radius: 34px;

	&:before {
		border-radius: 50%;
	}
}

.switch {
	position: absolute;
	cursor: pointer;
	top: 0;
	inset-inline-start: 0;
	inset-inline-end: 0;
	bottom: 0;
	background-color: transparent;
	-webkit-transition: 0.4s;
	transition: 0.4s;
	border: 1px solid var(--bd-border-primary);

	&:before {
		position: absolute;
		content: "";
		height: 14px;
		width: 14px;
		inset-inline-start: 3px;
		bottom: 3px;
		background-color: #4B4E52;
		-webkit-transition: 0.4s;
		transition: 0.4s;
	}
}

input {
	&:checked {
		+ {
			.switch {
				background-color: #096BD8;
				border-color: #096BD8;

				&:before {
					transform: translateX(16px);
					background-color: var(--bd-white);
				}
			}
		}
	}
}

.otp-input {
	display: flex;
	gap: 10px;
	justify-content: center;

	input[type="text"] {
		padding: 0 15px;
		font-size: 32px;
		font-weight: 600;
		height: 56px;
		width: 56px;
		color: var(--bd-heading);
	}
}


.input-group-text {
	display: flex;
	align-items: center;
	padding: 5px 15px;
	font-size: 16px;
	font-weight: 400;
	line-height: 1.5;
	color: var(--bd-heading);
	text-align: center;
	white-space: nowrap;
	background-color: var(--bd-border-primary);
	border: 1px solid var(--bd-border-primary);
	border-radius: 0;
}
.form-control:focus {
	color: var(--bd-black);
	background-color: transparent;
	border-color: transparent;
	outline: 0;
	box-shadow: none;
}

// text editor 
.tox .tox-statusbar {
	display: none !important;
}