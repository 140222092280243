@use '../../utils' as *;

/*----------------------------------------*/
/*  4.10 App css
/*----------------------------------------*/

.download {
	&-space {
		margin-top: -189px;
		@media #{$xs} {
			margin-top: 0;
		}
	}
	&-bg {
		position: relative;
		overflow: hidden;
		background-color: var(--bd-primary);
		border-radius: 10px;
		display: grid;
		grid-template-columns: repeat(2, 1fr);
		justify-content: end;
		height: 410px;
		align-items: center;
		align-content: center;
		@media #{$md,$sm,$xs} {
			grid-template-columns: repeat(1, 1fr);
			height: 100%;
			gap: 30px;
		}
	}
	&-content {
		margin-left: 60px;
		@media #{$lg} {
			margin-left: 40px;
		}
		@media #{$md,$sm,$xs} {
			margin-left: 30px;
  			margin-top: 30px;
			margin-right: 30px;
		}
	}
	&-btn {
		&-wrap {
			display: flex;
			gap: 16px;
		}
		&-title {
			color: var(--bd-white);
			margin-bottom: 20px;
		}
	}
	&-thumb {
		position: relative;
		z-index: 5;
		img {
			width: 100%;
			min-height: 510px;
			object-fit: cover;
			@media #{$lg} {
				min-height: 475px;
			}
			@media #{$md,$sm} {
				min-height: 100%;
			}
		}
		&-wrap {
			position: relative;
			&::before {
				position: absolute;
				content: "";
				right: 0;
				top: 0;
				background-color: var(--bd-tertiary);
				clip-path: polygon(0 0, 100% 0%, 100% 100%, 25% 100%);
				height: 515px;
				width: 100%;
				border-radius: 0 10px 10px 0;
				z-index: 1;
				@media #{$lg} {
					height: 100%;
				}
			}
		}
	}
}