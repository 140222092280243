@use '../utils' as *;

/*---------------------------------
	1.1 Theme SCSS
---------------------------------*/

/*-----------------------------------------------------------------------------------

    Template Name: Tourigo - Tour & Travel HTML Template
    Author: Bdevs
    Support: https://support.bdevs.net/
    Description: Tour & Travel related HTML Template
    Version: 1.0
	Developer: @mazharulislamt2

-----------------------------------------------------------------------------------

/************ TABLE OF CONTENTS ***************

	-----------------
    01. THEME SCSS
	-----------------
		1.1 theme
		1.2 typography
		1.3 spacing

	-----------------
    01. utils SCSS
	-----------------
		1.1 mixins
		1.2 root
		1.3 extends
		1.4 breakpoints
		
	-----------------
    02. COMPONENTS SCSS
	-----------------
		2.1 animation
		2.2 avatar
		2.3 background
		2.4 backtotop
		2.5 breadcrumb
		2.6 buttons
		2.7 carousel 
		2.8 color.scss
		2.9 forms
		2.10 offcanvas
		2.11 pagination
		2.12 preloader
		2.13 section Title
		2.14 shortcodes
		2.15 social
		2.16 tab
		2.17 tooltip
		2.18 video
		2.19 index

	-----------------
    03. layout
	-----------------
		-----------------
		3.1.1 banner SCSS
		-----------------
			3.1.1 banner-1
			3.1.2 banner-2
			3.1.3 banner-3
			3.1.4 banner-4
			3.1.5 banner-5
			3.1.6 banner-common
			3.1.7 index

		-----------------
		3.1.2 blog SCSS
		-----------------
			3.2.1 blog-tag
			3.2.2 blog
			3.2.3 postbox
			3.2.4 recent-post
			3.2.5 sidebar-tag
			3.2.6 index

		-----------------
		3.1.3 ecommerce SCSS
		-----------------
			3.3.1 cart
			3.3.2 checkout
			3.3.3 order
			3.3.4 shop
			3.3.5 index

		-----------------
		3.1.4 footer SCSS
		-----------------
			3.4.1 footer-1
			3.4.2 footer-2
			3.4.3 footer-3
			3.4.4 footer-4
			3.4.5 footer-card
			3.4.6 footerCopyrights
			3.4.7 footerDefault
			3.4.8 index

		-----------------
		3.1.5 header SCSS
		-----------------
			3.5.1 header-1
			3.5.2 header-3
			3.5.3 header-4
			3.5.4 header-common
			3.5.5 header-top
			3.5.6 index
		-----------------
		3.1.6 menu SCSS
		-----------------
			3.6.1 meanmenu
			3.6.2 menu
			3.5.3 index

	-----------------
    04. Page SCSS
	-----------------
		4.1 about
		4.2 activity
		4.3 booking
		4.4 brand
		4.5 contact
		4.6 counter
		4.7 cta 
		4.8 demo
		4.9 destination
		4.10 download
		4.11 error
		4.12 faq
		4.13 feedback
		4.14 flatpickr-calendar
		4.15 login
		4.16 newsletter
		4.17 offer
		4.18 popup-search
		4.19 pricing
		4.20 privacy-policy
		4.21 progress-bar
		4.22 sidebar
		4.23 slider
		4.24 team
		4.25 testimonial
		4.26 tour
		4.27 travel
		4.28 trip
		4.29 whyChose
		4.30 widget
		4.30 index

/*----------------------------------------*/
/*  1.1 Theme Default
/*----------------------------------------*/

// Google Fonts
@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@200..700&family=Roboto:ital,wght@0,300;0,400;0,500;0,700;0,900;1,100&display=swap');



/*----------------------------------------
   Icomoon customize
-----------------------------------------*/
i[class^="icon-"] {
	line-height: 1;
	position: relative;
}

.icomoon-icon {
	display: inline-block;
	font-size: 30px;
	i {
		margin-right: 5px;
	}
}
/*----------------------------------------
   Flaction customize
-----------------------------------------*/
i[class^="flaticon-"] {
	line-height: 1;
	top: 2px;
	position: relative;
	@extend %bd-transition-3;
}

/*----------------------------------------
   Bootstrap customize
-----------------------------------------*/
.container, .container-fluid, .container-lg, .container-md, .container-sm, .container-xl, .container-xxl {
	--bs-gutter-x: 2.4rem;
}
@media (min-width: 1441px) {
	.container,
	.container-lg,
	.container-md,
	.container-sm,
	.container-xl,
	.container-xxl {
		max-width: 1320px;
	}
}
.row {
    --bs-gutter-x: 2.4rem;
}
.g-10,
.gx-10 {
	--bs-gutter-x: 10px;
}
.g-20,
.gx-20 {
	--bs-gutter-x: 20px;
}
.gy-24 {
	--bs-gutter-y: 24px;
}
.g-30,
.gx-30 {
	--bs-gutter-x: 30px;
}
.g-30,
.gy-30 {
	--bs-gutter-y: 30px;
}
.g-40,
.gx-40 {
	--bs-gutter-x: 40px;
}
.g-40,
.gy-40 {
	--bs-gutter-y: 40px;
}
.g-50,
.gx-50 {
	--bs-gutter-x: 50px;
}
.g-50,
.gy-50 {
	--bs-gutter-y: 50px;
}
.g-60,
.gy-60 {
	--bs-gutter-y: 60px;
}
/*---------------------------------
    1.2 Common Classes
---------------------------------*/
.w-img{
	& img{
		width: 100%;
	}
}
.m-img{
	& img{
		max-width: 100%;
	}
}
.fix {
    overflow:hidden
}
.clear{
    clear: both;
}

.f-left {
	float: left
}
.f-right {
	float: right
}
.z-index-1{
	z-index: 1;
}
.z-index-11{
	z-index: 11;
}
.opacity-05 {
	opacity: 0.5;
}
.overflow-y-visible{
	overflow-x: hidden;
	overflow-y: visible;
}
.p-relative{
	position: relative;
}
.position-relative{
	position: relative;
}
.p-absolute{
	position: absolute;
}
.position-absolute{
	position: absolute;
}
.include-bg{
	@include background();
}
.hr-1 {
	border-top: 1px solid rgb(232, 232, 232);
}
.x-clip {
	overflow-x: clip;
}
.o-xs {
	@media #{$xs} {
		overflow-x: hidden;
	}
}
.overflow-visible {
	overflow: visible;
}
.valign {
	@include flexbox();
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-ms-flex-align: center;
}

/*----------------------------------------
    Body Overlay 
-----------------------------------------*/
.body__overlay {
	background-color: var(--bd-common-heading);
	height: 100%;
	width: 100%;
	position: fixed;
	top: 0;
	z-index: 9999;
	left: 0;
	opacity: 0;
	visibility: hidden;
	@extend %bd-transition-3;
}
.body__overlay {
	background-color: var(--bd-common-heading);
	height: 100%;
	width: 100%;
	position: fixed;
	top: 0;
	z-index: 9999;
	left: 0;
	opacity: 0;
	visibility: hidden;
	@extend %bd-transition-3;
}
.body__overlay.opened {
	opacity: 0.7;
	visibility: visible;
}

.image-overly {
	position: relative;
	overflow: hidden;

	a {
		&::before {
			position: absolute;
			content: "";
			display: block;
			height: 100%;
			width: 100%;
			top: 0;
			left: 0;
			opacity: .8;
			border-radius: 10px;
			transition: all 0.3s ease-in-out;
			background: linear-gradient(180deg, rgba(28, 31, 57, 0.00) 17.61%, #0D1B2A 100%);
		}

		&::after {
			position: absolute;
			content: "";
			display: block;
			height: 100%;
			width: 100%;
			top: 0;
			left: 0;
			opacity: 0;
			border-radius: 10px;
			transition: all 0.3s ease-in-out;
			background: linear-gradient(180deg, rgba(0, 108, 228, 0) 0%, rgba(0, 108, 228, 0.75) 100%);
		}

		&:hover {
			&::before {
				opacity: 0;
			}

			&::after {
				opacity: 1;
			}
		}
	}
}

/*----------------------------------------
   Text Underline
-----------------------------------------*/
.underline {
	& a {
		display: inline;
		background-image: linear-gradient(var(--bd-primary), var(--bd-primary)), linear-gradient(var(--bd-primary), var(--bd-primary));
		background-size: 0% 1px, 0 1px;
		background-position: 100% 100%, 0 83%;
		background-repeat: no-repeat;
		transition: background-size 0.4s linear;
		line-height: 1.4;
		padding: 2% 0;

		&:hover {
			background-size: 0 1px, 100% 1px;
			color: var(--bd-primary);
		}
	}
}
.underline-two {
	& a {
		display: inline;
		background-image: linear-gradient(var(--bd-tertiary), var(--bd-tertiary)), linear-gradient(var(--bd-tertiary), var(--bd-tertiary));
		background-size: 0% 1px, 0 1px;
		background-position: 100% 100%, 0 83%;
		background-repeat: no-repeat;
		transition: background-size 0.4s linear;
		line-height: 1.4;
		padding: 2% 0;

		&:hover {
			background-size: 0 1px, 100% 1px;
			color: var(--bd-tertiary);
		}
	}
}
/*----------------------------------------
   Mfp customize
-----------------------------------------*/
.mfp-iframe-holder {
	.mfp-content {
		line-height: 0;
		width: 100%;
		max-width: 1280px;
		@media #{$xxl} {
			max-width: 1000px;
		}
		@media #{$xl} {
			max-width: 850px;
		}
		@media #{$lg} {
			max-width: 820px;
		}
		@media #{$md} {
			max-width: 750px;
		}
	}
}
.mfp-close {
	-webkit-transform: rotate(0deg);
	-ms-transform: rotate(0deg);
	transform: rotate(0deg);
	&:hover{
		color: var(--bd-white);
	}
	&::after {
		position: absolute;
		content: "\f00d";
		height: 100%;
		width: 100%;
		font-family: var(--bd-ff-fontawesome);
		font-size: 31px;
		font-weight: 200;
		right: -20px;
		margin-top: -25px;
		@media #{$xs,$sm,$md} {
			right: 15px;
			margin-top: -30px;
		}
	}
}

.image-bg {
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;
}


.has-seperator {
	position: relative;
	&::before {
		position: absolute;
		content: " ";
		width: 2px;
		height: 15px;
		right: -18px;
		top: 50%;
		background: rgba(129, 129, 129, 0.2);
		transform: translateY(-50%);
	  }
  }