@use "../../utils" as *;

/*----------------------------------------*/
/*  4.22 Sidebar css
/*----------------------------------------*/

.sidebar {
	&-widget-divider {
		padding-top: 30px;
		border-bottom: 1px solid var(--bd-border-primary);
		margin-bottom: 40px;
	}
	&-sticky {
		position: sticky;
		top: 120px;
	}
	&-wrapper {
		margin-left: 24px;
		@media #{$xl,$lg,$md,$sm,$xs} {
			margin-left: 0;
		}
		&.sidebar-left {
			margin-left: 0;
			margin-right: 24px;
			@media #{$xl,$lg,$md,$sm,$xs} {
				margin-right: 0;
			}
		}
	}
	&-widget-wrapper {
		border: 1px solid var(--bd-border-primary);
		padding: 45px 30px;
		border-radius: 8px;
	}
	&-search-form {
		position: relative;

		input {
			width: 100%;
			background: var(--bd-flash-white);
			border-radius: 4px;
			height: 56px;
			padding-inline-start: 25px;
			padding-inline-end: 55px;
			-webkit-transition: all 0.3s ease-out 0s;
			-moz-transition: all 0.3s ease-out 0s;
			-ms-transition: all 0.3s ease-out 0s;
			-o-transition: all 0.3s ease-out 0s;
			transition: all 0.3s ease-out 0s;
			resize: none;
		}

		button {
			font-size: 20px;
			color: #121212;
			position: absolute;
			top: 50%;
			right: 0;
			transform: translateY(-50%);
			width: 60px;
			height: 60px;
			background-color: transparent;
			border-radius: 50%;
		}
	}
	&-widget {
		.recent {
			&-post {
				display: flex;
				align-items: center;
				gap: 5px;
				@media #{$xs} {
					flex-direction: column;
					align-items: start;
				}
				&:not(:last-child) {
					margin-bottom: 15px;
				}
				&-title {
					margin-bottom: 5px;
				}
				&-thumb {
					width: 90px;
					height: 90px;
					min-width: 90px;
					border-radius: 4px;
					overflow: hidden;
					position: relative;
					&:hover {
						&::after {
							height: 250%;
							transition: all 600ms linear;
							background-color: transparent;
						}
					}
				
					&::after {
						content: "";
						position: absolute;
						width: 200%;
						height: 0%;
						left: 50%;
						top: 50%;
						background-color: rgba(255, 255, 255, .3);
						transform: translate(-50%, -50%) rotate(-45deg);
						z-index: 1;
					}
					@media #{$sm} {
						width: 150px;
						height: 110px;
						min-width: 150px;
					}
					@media #{$xs} {
						width: 100%;
						height: 100%;
						max-width: 100%;
						margin-bottom: 15px;
						margin-right: 0;
					}
					img {
						width: 100%;
						height: 100%;
						object-fit: cover;
					}
				}

				&-date {
					span {
						color: var(--bd-primary);
						margin-right: 2px;
					}
				}
				&-price {
					color: var(--bd-primary);
				}
			}
		}
		&.widget_categories {
			ul {
				li {
					&:not(:last-child) {
						margin-bottom: 10px;
					}

					&:hover {
						background-color: var(--bd-primary);
						color: var(--bd-white);
					}
					align-items: center;
					display: flex;
					justify-content: space-between;
					color: var(--bd-heading);
					background-color: var(--bd-flash-white);
					padding: 0 25px;
					border-radius: 8px;
					font-weight: 600;
					transition: all 0.3s ease-in-out;
					height: 56px;
				}

			}
		}
		.best-sell-post {
			display: flex;
			align-items: center;
			@media #{$xs} {
				flex-direction: column;
				align-items: start;
			}
			&:not(:last-child) {
				margin-bottom: 15px;
			}

			&-thumb {
				width: 90px;
				height: 90px;
				min-width: 90px;
				border-radius: 4px;
				overflow: hidden;
				@media #{$sm} {
					width: 150px;
					height: 110px;
					min-width: 150px;
				}
				@media #{$xs} {
					width: 100%;
					height: 100%;
					max-width: 100%;
					margin-bottom: 15px;
					margin-right: 0;
				}
				img {
					width: 100%;
					height: 100%;
					object-fit: cover;
				}
			}

			&-location {
				display: flex;
				align-items: center;
				gap: 10px;
				margin-bottom: 5px;
				&:hover {
					color: var(--bd-primary);
				  }
			}
			&-price {
				color: var(--bd-primary);
				font-size: 18px;
				font-weight: 600;
			}
		}
		
	}
	&-widget-thumb {
		position: relative;
		border-radius: 8px;
		&::before {
			position: absolute;
			content: "";
			left: 0;
			top: 0;
			background-color: var(--bd-primary);
			opacity: .8;
			width: 100%;
			height: 100%;
			border-radius: 8px;
			right: 0;
		}
	
		img {
			border-radius: 8px;
		}
	}
	&-widget-content {
		text-align: center;
		position: absolute;
		left: 50%;
		transform: translate(-50%, -50%);
		top: 50%;
		z-index: 2;

		.bd-play-btn {
			background-color: var(--bd-white);
			i {
				color: var(--bd-primary);
			}
			@media #{$xs} {
				margin-bottom: 10px;
			}
		}
		p {
			color: var(--bd-text-secondary);
		}
		h5 {
			color: var(--bd-white);
			&:hover {
				color: var(--bd-tertiary);
			}
		}
		.bd-text-btn {
			color: var(--bd-white);
			&:hover {
				color: var(--bd-tertiary);
			}
		}
	}
	&-widget-range {
		& .noUi-horizontal {
			height: 5px;
		}
		.noUi-target {
			background: var(--bd-flash-white);
			border-radius: 4px;
			border: none;
			box-shadow: none;
		}
		& .noUi-connect {
			background: var(--bd-primary);
		}
		& .noUi-handle {
			border: none;
			border-radius: 4px;
			background: var(--bd-primary);
			cursor: default;
			box-shadow: none;
		}
		& .noUi-horizontal .noUi-handle {
			width: 20px;
			height: 20px;
			right: -14px;
			top: -8px;
			border-radius: 50%;
		}
		& .noUi-handle::after, .noUi-handle::before {
			display: none;
		}
		& .price-filter {
			& input {
				border: none;
				font-size: 18px;
				margin-left: -25px;
				font-weight: 600;
				height: 40px;
				color: var(--bd-heading);
			}
		}
	}
	&-booking-form {
		.input-box {
			margin-bottom: 14px;
			input {
				background: var(--bd-flash-white);
				color: #1A1A1A;
			}
			textarea {
				background: var(--bd-flash-white);
				color: #1A1A1A;
				min-height: 201px;
				resize: none;
			}
		}
	}
}
