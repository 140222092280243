@use "../../utils" as *;

/*----------------------------------------*/
/*  4.20 Privacy Policy css
/*----------------------------------------*/

.policy {
    &-wrapper {
        padding: 50px 60px 60px 50px;
		background-color: var(--bd-white);
		box-shadow: 0px 4px 80px rgba(0, 0, 0, 0.12);
		border-radius: 8px;
        @media #{$sm} {
            padding: 40px 40px;
        }
        @media #{$xs} {
            padding: 30px 20px;
        }
    }
    &-meta {
        font-weight: 500;
        font-size: 16px;
        letter-spacing: -0.02em;
        margin-bottom: 12px;
    }
    &-title {
		margin-bottom: 10px;
        font-size: 28px;
        @media #{$xs} {
            font-size: 22px;
        }
    }
    &-item {
		&:not(:last-child) {
			margin-bottom: 25px;
		}
        ul {
            li {
                font-size: 16px;
                position: relative;
                padding-left: 15px;
                list-style: none;
                &::after {
                    position: absolute;
                    content: "";
                    left: 0;
                    top: 8px;
                    width: 6px;
                    height: 6px;
                    background-color: var(--bd-primary);
                    border-radius: 50%;
                }
                &:not(:last-child) {
                    margin-bottom: 5px;
                }
            }
        }
    }
    &-contact {
        ul {
            margin-bottom: 10px;
            li {
                list-style: none;
                font-size: 16px;
                color: var(--bd-black);
            }
        }
    }
    &-address {
        p {
            font-size: 16px;
            line-height: 1.5;
        }
    }
}