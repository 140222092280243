@use '../../utils' as *;

/*----------------------------------------*/
/*  4.8 Demo css
/*----------------------------------------*/

// Demo banner
.bd-demo-banner-area {
    background-repeat: no-repeat;
    background-position: center bottom;
    background-size: cover;
    position: relative;
    padding-top: 230px;
    padding-bottom: 230px;
    @media #{$sm} {
        padding-bottom: 340px;
    }
    @media #{$xs} {
        padding-top: 150px;
        padding-bottom: 70px;
    }
}
.demo-banner-shape {
    .banner-shape-one {
        top: 160px;
        right: 300px;
        z-index: 2;
        @media #{$xs} {
            right: 30px;
        }
    }
    .banner-shape-two {
        top: 190px;
        right: 385px;
        z-index: 2;
        @media #{$xs} {
            right: 100px;
        }
    }
    .banner-shape-six {
        	animation-duration: 7s;
			animation-timing-function: ease-in-out;
			animation-iteration-count: infinite;
			animation-name: palneUp;
       @media #{$sm} {
        top: 200px;
        left: 450px;
       }
    }
}
.bd-icon i {
	color: var(--bd-rating-color);
}
.demo-banner-top-inner {
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    gap: 10px 50px;
    flex-wrap: wrap;
}

.bd-demo-banner-content {
    padding-right: 60px;
    @media #{$lg,$md,$sm,$xs} {
        padding-right: 0px;
    }
}

.demo-banner-top {
    .content {
        .subtitle {
            color: var(--bd-primary);
            font-family: var(--bd-ff-body);
            font-size: 18px;
            font-weight: var(--bd-fw-regular);
            line-height: 1.444;
            letter-spacing: 2.88px;
        }
    }
}

.demo-banner-thumb-wrapper {
    margin-right: -120px;

    @media #{$lg,$md,$sm,$xs} {
        margin-right: 0px;
    }
    .thumbnail {
        @media #{$xs} {
            display: none;
        }
    }
}

.mobile-thumb {
    position: absolute;
    left: 0;
    top: 100px;
    @media #{$xs} {
        position: initial;
    }

}
.mobile-thumb img {
	max-width: 230px;
    @media #{$xs} {
        max-width: 100%;
    }
}

.demo-banner-title {
    @media #{$lg} {
        font-size: 38px;
    }
    @media #{$xs} {
        font-size: 28px;
    }
}

// demo-tab-area style
.demo-tab-area {
    position: relative;
    z-index: 1;
    overflow: hidden;
}
.landing-nav-tab {
    border-radius: 8px;
    border: 1px solid #E4E4E4;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    @media #{$md} {
        justify-content: center;
    }
    li {
        margin: 0;
        flex-basis: 25%;
        @media #{$md} {
            flex-basis: 33%;
        }
        @media #{$sm} {
            flex-basis: 50%;
        }
        @media #{$xs} {
            flex-basis: 50%;
        }
        @media #{$xs} {
            flex-basis: 100%;
        }
        button {
            display: inline-block;
            padding: 20px;
            border: none !important;
            border-radius: 8px;
            display: flex;
            align-items: center;
            justify-content: center;
            @media #{$lg} {
                padding: 10px;
            }
            i {
                margin-right: 10px;
                font-size: 40px;
                color: var(--bd-dark);
            }
            span {
                color: var(--bd-dark);
                font-size: 16px;
                @media #{$lg} {
                    font-size: 14px;
                }
                @media #{$md} {
                    font-size: 14px;
                }
                @media #{$sm} {
                    font-size: 14px;
                }
            }

            &.active {
                border: none;
                border-radius: 8px;
                background: #6707FE !important;
                color: var(--bd-white) !important;
                i {
                    color: var(--bd-white);
                }
                span {
                    color: var(--bd-white);
                }
            }
        }
    }
}
.landing-tab-content {
    .thumbnail {
        background: rgba(103, 7, 254, 0.10);
        filter: drop-shadow(0px 4px 17px rgba(0, 0, 0, 0.15));
    }
}

// service style
.bd-landing-service {
    .inner {
        border-radius: 6px;
        background: var(--bd-flash-white);
        padding: 46px 40px;
        @media #{$md} {
            padding: 45px 30px;
        }
        @media #{$xs} {
            padding: 35px 25px;
        }
        .inner-icon i {
            font-size: 60px;
            color: var(--bd-primary);
            margin-bottom: 10px;
            display: block;
        }
        .content {
            .title {
                font-size: 26px;

                @media #{$sm,$md} {
                    font-size: 24px;
                }
            }
            .btn-doc {
                border-radius: 50px;
                border: 1px solid var(--bd-border-primary);
                font-size: var(--font-size-b1);
                font-family: var(--bd-ff-body);
                font-weight: var(--s-semi-bold);
                line-height: 16px;
                transition: all 0.3s ease-in-out;
                display: inline-flex;
                align-items: center;
                padding: 0 30px;
                height: 55px;
                &:hover {
                    background-color: var(--bd-primary);
                    color: var(--bd-white);
                }
            }
        }
    }
}
// Features item
.features-title {
    margin-bottom: 20px;
    font-size: 22px;
}
.features-wrapper {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 30px 30px;

    @media #{$md} {
        grid-template-columns: repeat(2, 1fr);
    }
    @media #{$sm,$xs} {
        grid-template-columns: 1fr;
    }
}
.features-content {
    >p {
        font-size: 16px;
        margin-bottom: 25px;
        color: var(--bd-white-rgba-2);
    }
}
.features-item {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 35px 35px;
    transition: .3s;
    z-index: 5;
    background-color: var(--bd-flash-white);
    border-radius: 8px;
    @media #{$xs,$lg} {
        padding: 30px 25px;
    }
    &:hover {
        .features-icon {
            transform: translateY(-3px) translate3d(0, -3px, 0);
        }
        .features-content {
            >p {
                color: var(--bd-primary);
            }
        }
        .features-title {
            color: var(--bd-heading);
        }
        .features-tag {
            &.green {
                span {
                    background: rgba(0, 128, 255, .2);
                    color: rgba(0, 128, 255, 1);
                }
            }
            &.purple {
                span {
                    color: rgba(137, 18, 252, 1);
                    background: rgba(136, 18, 252, 0.2);
                }
            }
            &.lilac {
                span {
                    color: rgba(205, 103, 153, 1);
                    background: rgba(205, 102, 153, 0.2);
                }
            }
            &.lime {
                span {
                    background: rgba(52, 168, 83, 0.2);
                    color: rgba(52, 168, 83, 1);
                }
            }
            &.red {
                span {
                    color: rgba(255, 30, 90, 1);
                    background: rgba(255, 30, 90, 0.2);
                }
            }
            &.red-2 {
                span {
                    color: rgba(227, 82, 45, 1);
                    background: rgba(227, 76, 38, 0.2);
                }
            }
            &.lemon {
                span {
                    background: rgba(136, 206, 2, 0.2);
                    color: rgba(82, 124, 1, 1);
                }
            }
            &.green-dark {
                span {
                    background: rgba(0, 128, 255, 0.2);
                    color: rgba(0, 128, 255, 1);
                }
            }
        }
        .features-icon.lilac {
            background: rgba(205, 102, 153, 0.2);
        }
        .features-icon.purple {
            background: rgba(136, 18, 252, 0.2);
        }
        .features-icon.green {
            background: rgba(0, 128, 255, .2)
        }
        .features-icon.lime {
            background: rgba(52, 168, 83, 0.2);
        }
        .features-icon.red {
            background: rgba(255, 30, 90, 0.2);
        }
        .features-icon.red-2 {
            background: rgba(227, 76, 38, 0.2);
        }
        .features-icon.green-dark {
            background: rgba(0, 128, 255, 0.2);
        }
    }
}
.features-icon {
    margin-bottom: 25px;
    transform: translate3d(0, 0, 0);
    transition: transform 0.3s cubic-bezier(0.21, 0.6, 0.44, 2.18);
    height: 70px;
    width: 70px;
    background: var(--bd-white);
    line-height: 67px;
    text-align: center;
    border-radius: 50%;
    img {
        max-width: 45px;
    }
}
.features-tag {
    span {
        padding: 5px 12px;
        background: var(--bd-primary);
        border-radius: 30px;
        font-size: 16px;
        color: var(--bd-white);
    }
    &:hover {
        &.green {
            span {
                background: rgba(12, 115, 184, 0.2);
                color: rgba(12, 115, 184, 1);
            }
        }
        &.purple {
            span {
                color: rgba(137, 18, 252, 1);
                background: rgba(136, 18, 252, 0.2);
            }
        }
        &.lilac {
            span {
                color: rgba(205, 103, 153, 1);
                background: rgba(205, 102, 153, 0.2);
            }
        }
        &.lime {
            span {
                background: rgba(52, 168, 83, 0.2);
                color: rgba(52, 168, 83, 1);
            }
        }
        &.red {
            span {
                color: rgba(255, 30, 90, 1);
                background: rgba(255, 30, 90, 0.2);
            }
        }
        &.lemon {
            span {
                background: rgba(136, 206, 2, 0.2);
                color: rgba(82, 124, 1, 1);
            }
        }
        &.green-dark {
            span {
                background: rgba(0, 128, 255, 0.2);
                color: rgba(0, 128, 255, 1);
            }
        }
    }
}

// Demo Presentation 
.home-page-demo {
    .item {
        a {
            padding: 20px;
            border-radius: 6px;
            display: block;
            transition: all 0.3s ease-in-out;
            background: var(--bd-flash-white);
            .thumbnail {
                width: 100%;
                img {
                    width: 100%;
                }
            }
            .content {
                text-align: center;
                padding-top: 20px;
                .title {
                    font-size: 18px;
                    margin-bottom: 0;
                    text-transform: capitalize;
                    &:hover {
                        color: var(--bd-primary);
                    }
                }
            }
            &:hover {
                transform: translateY(-4px);
            }
        }
    }
}
.coming-soon {
    .thumbnail img {
        filter: blur(4px);
    }
}
// inner-pages
.inner-page-presentation-area {
    overflow: hidden;
}
.inner-pages-animation {
    animation: marquee 20s linear 0s infinite normal;
    animation-play-state: running;
    flex-wrap: nowrap;
    &:hover {
        animation-play-state: paused;
    }
    &.inner-pages-animation--2 {
        animation-name: marquee_rev;
        &:hover {
            animation-play-state: paused;
        }
    }
}

.demo-wrapper {
    &.inner-pages-wrapper {
        .demo-item {
            .solo-demo {
                a {
                    border-radius: 6px;
                    background: var(--bd-white);
                    box-shadow: 20px 30px 70px rgba(3, 4, 28, 0.1);
                    padding: 20px;
                    display: block;

                    .thumbnail {
                        width: 100%;

                        img {
                            border-radius: 6px;
                            width: 100%;
                        }
                    }

                    .content {
                        .title {
                            font-size: 18px;
                            margin-top: 20px;
                            transition: var(--transition);
                            display: inline-block;
                            position: relative;
                            margin-bottom: 0;

                            &::before {
                                content: " ";
                                position: absolute;
                                left: 0;
                                bottom: 0;
                                width: 0;
                                height: 1px;
                                background-color: var(--bd-primary);
                                transition: var(--transition);
                            }

                            &:hover {
                                color: var(--bd-primary);

                                &::before {
                                    width: 100%;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

// element css
.single-elements {
    border: 0 none;
    padding: 0px 30px;
    text-transform: capitalize;
    background: var(--bd-flash-white);
    height: 60px;
    border-radius: 6px;
    font-size: 16px;
    letter-spacing: -0.6px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    min-width: 100px;
    transition: 0.4s;
    color: var(--bd-heading);
    i {
        font-size: 16px;
        margin-right: 8px;
        font-weight: 600;
        transition: .3s;
        color: var(--bd-heading);
    }
    &:hover {
        background-color: var(--bd-primary);
        color: var(--bd-white);

        i {
            color: var(--bd-white);
        }
    }
}
.elements {
    &-category-list {
        animation: marquee 20s linear 0s infinite normal;
        animation-play-state: running;
        flex-wrap: nowrap;
        a {
            max-width: max-content;
            margin: 0 5px;
        }
    }
    &-category-list-2 {
        animation-name: marquee_rev;
    }
}

// Review area start
.customer-review-area {
    background: var(--bd-flash-white);
    overflow: hidden !important;
    max-height: 1500px;
    position: relative;
    @media #{$md,$sm,$xs} {
        padding: 80px 0 120px;
    }
    &::before {
        content: "";
        position: absolute;
        left: 0;
        right: 0;
        width: 100%;
        pointer-events: none;
        top: 0;
        min-height: 650px;
        background: linear-gradient(180deg, #F2F2F2 50%, #F2F2F2 0%, rgba(242, 242, 242, 0) 60%);
        z-index: 10;
        @media #{$sm,$xs} {
            min-height: 450px;
        }
    }
    &::after {
        position: absolute;
        content: "";
        background: linear-gradient(180deg, rgba(242, 242, 242, 0) 0%, #F2F2F2 75%);
        width: 100%;
        height: 400px;
        bottom: 0;
        left: 0;
    }
    .customer-review-title {
        position: absolute;
        top: 100px;
        left: 0;
        right: 0;
        z-index: 12;
        text-align: center;
        padding: 0px 32%;
        @media #{$md} {
            padding: 0px 20%;
        }
        @media #{$sm} {
            padding: 0px 15%;
        }
        @media #{$xs} {
            padding: 0px 5%;
        }
    }
    .customer-review-wrapper {
        display: flex;
        margin-right: auto;
        margin-left: auto;
        position: relative;
        top: 220px;
        @media #{$lg} {
            display: grid;
            grid-template-columns: repeat(3, 1fr);
        }
        @media #{$md} {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
        }
        @media #{$sm,$xs} {
            display: block;
        }
        .customer-review-wrap {
            animation: tourigoTestimonialScroll 40s linear infinite;
            transition: all 0.5s ease-in;
            animation-duration: 35s;
            &:hover {
                animation-play-state: paused;
            }
            .customer-review-style-1 {
                display: flex;
                padding: 15px;
                @media #{$lg} {
                    padding: 10px;
                }
                .content {
                    max-width: 300px;
                    border-radius: 15px;
                    background: var(--bd-white);
                    padding: 40px 40px 40px 30px;
                    margin-top: 0;
                    margin-bottom: 0;
                    @media #{$md,$sm,$xs} {
                        max-width: 100%;
                    }
                    .rating-icon {
                        margin-bottom: 13px;
                    }
                    .content-title {
                        margin-bottom: 15px;
                        span {
                            color: #141417;
                            font-family: var(--bd-ff-body);
                            font-size: 18px;
                            font-weight: 600;
                            line-height: 30px;
                        }
                    }
                    .text {
                        font-size: 16px;
                        font-weight: 400;
                        line-height: 28px;
                        margin-bottom: 15px;
                    }
                    .info {
                        .title {
                            color: #141417;
                            font-size: 18px;
                            font-style: normal;
                            font-weight: 600;
                            line-height: 30px;
                            margin-bottom: 0;

                            span {
                                color: #808080;
                                font-size: 16px;
                            }
                        }
                    }
                }
            }
        }
    }
}


.demo-faq {
	margin-right: 30px;
    @media #{$md,$sm,$xs} {
        margin-right: 0;
    }
}
.footer-demo-description {
	color: rgba(255, 255, 255, 0.7);
}


@keyframes palneUp {
	0% {
		transform: translateY(0);
		opacity: 0;
	  }
	
	  50% {
		opacity: .8;
	  }
	
	  100% {
		transform: translateY(-50px);
		opacity: 0;
	  }
}

// keyframes animations
@keyframes marquee {
    0% {
        transform: translateX(0);
    }
    100% {
        transform: translateX(-50%);
    }
}
@keyframes marquee_rev {
    100% {
        transform: translateX(0);
    }
    0% {
        transform: translateX(-50%);
    }
}
@keyframes tourigoTestimonialScroll {
    from {
        transform: translateY(0)
    }
    to {
        transform: translateY(-50%)
    }
}
@keyframes stroke {
    0%   {
        fill: rgba(0,108,228,0); stroke: rgba(255,183,0,1);
        stroke-dashoffset: 25%; stroke-dasharray: 0 50%; stroke-width: 2;
    }
    70%  {fill: rgba(0,108,228,0); stroke: rgba(255,183,0,1); }
    80%  {fill: rgba(0,108,228,0); stroke: rgba(255,183,0,1); stroke-width: 3; }
    100% {
        fill: rgba(0,108,228,1); stroke: rgba(255,183,0,0);
        stroke-dashoffset: -25%; stroke-dasharray: 50% 0; stroke-width: 0;
    }
}