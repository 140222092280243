@use '../../utils' as *;

/*----------------------------------------*/
/*  4.14 flatpickr calendar css
/*----------------------------------------*/

.flatpickr-calendar {
	padding: 10px 10px;
	width: auto;
}
.flatpickr-day.today {
	border-color: var(--bd-border-tertiary);
}
.flatpickr-day.inRange, .flatpickr-day.prevMonthDay.inRange, .flatpickr-day.nextMonthDay.inRange, .flatpickr-day.today.inRange, .flatpickr-day.prevMonthDay.today.inRange, .flatpickr-day.nextMonthDay.today.inRange, .flatpickr-day:hover, .flatpickr-day.prevMonthDay:hover, .flatpickr-day.nextMonthDay:hover, .flatpickr-day:focus, .flatpickr-day.prevMonthDay:focus, .flatpickr-day.nextMonthDay:focus {
	cursor: pointer;
	outline: 0;
	border-color: var(--bd-primary);
	background: var(--bd-primary);
    color: var(--bd-white);
}
.flatpickr-day.today:hover, .flatpickr-day.today:focus {
	border-color: var(--bd-primary);
	background: var(--bd-primary);
	color: var(--bd-white);
}
.flatpickr-day.inRange {
	border-radius: 0;
	-webkit-box-shadow: none;
	box-shadow: none;
}
