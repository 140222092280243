@use '../../utils' as *;
/*----------------------------------------*/
/*  3.1.1 banner Style 1
/*----------------------------------------*/

// Banner style

.banner {
	&-height {
		padding-top: 160px;
		min-height: 930px;
		@media #{$xl} {
			min-height: 750px;
		}
		@media #{$lg} {
			min-height: 630px;
		}
		@media #{$md} {
			padding-top: 100px;
    		padding-bottom: 80px;
		}
		@media #{$sm,$xs} {
			padding-top: 60px;
			padding-bottom: 80px;
			min-height: 780px;
		}
	}
	&-content {
		max-width: 550px;
	}
	&-title {
		.yellow-shape {
			position: relative;
			img {
				position: absolute;
				bottom: 1px;
				left: 0;
				z-index: -1;
				-webkit-animation: section-animation 3s infinite;
				animation: section-animation 3s infinite;
			}
		}
	}
	&-thumb {
		&-wrapper {
			@media #{$lg,$md,$sm,$xs} {
				display: flex;
				justify-content: center;
				gap: 24px;
			}
			}
		&-one {
			position: relative;
			overflow: hidden;
			display: block;
			max-width: 344px;
			border-radius: 16px;
			&:hover {
				&::after {
					height: 250%;
					transition: all 600ms linear;
					background-color: transparent;
				}
			}
			&::after {
				content: "";
				position: absolute;
				width: 200%;
				height: 0%;
				left: 50%;
				top: 50%;
				background-color: rgba(255,255,255,.3);
				transform: translate(-50%,-50%) rotate(-45deg);
				z-index: 1;
			}
			@media #{$xxl} {
				margin-left: -80px;
			}
			@media #{$xl} {
				max-width: 270px
			}
			img {
				width: 100%;
				border-radius: 16px;
				@media #{$xl} {
					max-width: 270px
				}
				@media #{$lg,$md} {
					max-width: 100%;
				}
				@media #{$sm,$xs} {
					max-width: 100%;
				}
			}
		}
		&-two {
			position: absolute;
			right: -166px;
			top: 80px;
			overflow: hidden;
			display: block;
			border-radius: 16px;
			max-width: 330px;
			&:hover {
				&::after {
					height: 250%;
					transition: all 600ms linear;
					background-color: transparent;
				}
			}
			&::after {
				content: "";
				position: absolute;
				width: 200%;
				height: 0%;
				left: 50%;
				top: 50%;
				background-color: rgba(255,255,255,.3);
				transform: translate(-50%,-50%) rotate(-45deg);
				z-index: 1;
			}
			@media #{$x3l} {
				right: -150px;
			}
			@media #{$xxl} {
				right: -30px;
  				top: 150px;
			}
			@media #{$xl} {
				right: 0;
			}
			@media #{$lg,$md} {
				position: inherit;
				right: 0;
  				top: 0;
				bottom: 0;
			}
			@media #{$sm,$xs} {
				position: unset;
			}
			img {
				width: 100%;
				border-radius: 16px;
				@media #{$xxl} {
					max-width: 290px;
				}
				@media #{$xl} {
					max-width: 255px;
				}
				@media #{$lg,$md} {
					max-width: 100%;
					height: 100%;
				}
				@media #{$sm,$xs} {
					max-width: 100%;
				}
			}
		}
	}

	&-shape {
		&-one {
			position: absolute;
			top: 143px;
			right: 194px;
			z-index: -1;
			animation: sunScaleUp 3s ease-in-out 1s infinite alternate-reverse both;
			@media #{$x3l,$xl} {
				right: 60px;
			}
			@media #{$xxl} {
				top: 200px;
  				right: 65px;
			}
			@media #{$lg} {
				top: 100px;
  				right: 28px;
			}
			@media #{$md} {
				top: 150px;
				right: 50px;
			}
			@media #{$xs} {
				top: 180px;
				right: 0;
				img {
					max-width: 150px;
				}
			}
		}
		&-two {
			position: absolute;
			top: 180px;
			right: 345px;
			z-index: -1;
			animation: skySlideRight 3s ease-in-out 1s infinite alternate-reverse both;
			@media #{$x3l} {
				right: 200px;
			}
			@media #{$xxl} {
				top: 230px;
  				right: 180px;
			}
			@media #{$xl} {
				right: 180px;
			}
			@media #{$lg} {
				top: 125px;
  				right: 200px;
			}
			@media #{$md} {
				top: 190px;
				right: 270px;
			}
			@media #{$xs} {
				right: 100px;
			}
		}
		&-three {
			position: absolute;
			right: 485px;
			bottom: 65px;
			z-index: -1;
			@media #{$xl} {
				right: 415px;
  				bottom: 25px;
			}
			@media #{$lg,$md,$sm,$xs} {
				display: none;
			}
		}
		&-four {
			position: absolute;
			right: 660px;
			bottom: 80px;
			animation: bounceUp 15s ease-in-out 1s infinite alternate-reverse both;
			@media #{$xl} {
				right: 610px;
			}
			@media #{$lg,$md,$sm,$xs} {
				display: none;
			}
		}
		&-five {
			position: absolute;
			left: 0;
			top: 665px;
			z-index: -1;
			@media #{$xl} {
				left: -335px;
				top: 565px;
			}
			@media #{$xxl} {
				left: -198px;
			}
			@media #{$lg} {
				left: -416px;
  				top: 518px;
			}
			@media #{$md,$sm,$xs} {
				display: none;
			}
		}
		&-six {
			position: absolute;
			top: 500px;
			left: 875px;
			transform: rotate(10deg);
			@media #{$xxl} {
				left: 670px;
			}
			@media #{$xl} {
				top: 395px;
				left: 538px;
			}
			@media #{$lg} {
				top: 350px;
				left: 435px;
			}
			@media #{$md} {
				top: 350px;
				left: 435px;
			}
			@media #{$xs} {
				top: 250px;
				right: 20px;
				left: inherit;
			}
		}
		&-seven {
			position: absolute;
			top: 554px;
			left: 820px;
			@media #{$xxl} {
				left: 620px;
			}
			@media #{$xl} {
				top: 452px;
				left: 487px;
			}
			@media #{$lg} {
				top: 410px;
				left: 382px;
			}
			@media #{$md} {
				top: 410px;
				left: 382px;
			}
			@media #{$sm,$xs} {
				display: none;
			}
		}
		&-eight {
			position: absolute;
			inset-inline-start: 950px;
			top: 260px;
			animation: opacity-on-off 3s ease-in-out 1s infinite alternate-reverse both;
			@media #{$xxl} {
				inset-inline-start: 630px;
				top: 150px;
			}
			@media #{$xl} {
				inset-inline-start: 500px;
  				top: 200px;
			}
			@media #{$lg} {
				inset-inline-start: 460px;
  				top: 200px;
			}
		  }
	}
	&-one-navigation {
		.banner-pagination {
			position: absolute;
			z-index: 99999;
			left: 0;
			right: 0;
			bottom: -150px;
			@media #{$xl} {
				bottom: -80px;
			}
			@media #{$lg,$md,$sm,$xs} {
				bottom: -50px;
			}
		}
		.banner-navigation-btn {
			@media #{$xxl,$xl,$lg,$md,$sm,$xs} {
				display: none;
			}
		}
	}
}


// banner one animation

@keyframes section-animation {
	0% {
		width: 0;
	}
	15% {
		width: 100%;
	}
	85% {
		opacity: 1;
	}
	90% {
		width: 100%;
		opacity: 0;
	}
	to {
		width: 0;
		opacity: 0;
	}
}

@keyframes sunScaleUp {
	0% {
		transform: translateY(0);
	}
	100% {
		transform: translateY(30px);
	}
}

@keyframes skySlideRight {
	0% {
		transform: translateX(0);
	}
	100% {
		transform: translateX(-30px);
	}
}

@keyframes bounceUp {
	0% {
		animation-timing-function: ease-in;
		opacity: 1;
		transform: translateY(-35px);
	}
	24% {
		opacity: 1;
	}
	40% {
		animation-timing-function: ease-in;
		transform: translateY(-24px);
	}
	65% {
		animation-timing-function: ease-in;
		transform: translateY(-12px);
	}
	82% {
		animation-timing-function: ease-in;
		transform: translateY(-6px);
	}
	93% {
		animation-timing-function: ease-in;
		transform: translateY(-4px);
	}
	25%,
	55%,
	75%,
	87% {
		animation-timing-function: ease-out;
		transform: translateY(0px);
	}
	100% {
		animation-timing-function: ease-out;
		opacity: 1;
		transform: translateY(0px);
	}
}
@keyframes opacity-on-off {
	0% {
		opacity: 0;
	}

	100% {
		opacity: 1;
	}
}