@use '../../utils' as *;

/*----------------------------------------*/
/*  4.3 Booking css
/*----------------------------------------*/
.booking-form-input-box {
    margin-bottom: 10px;
}

.booking-form {
    padding: 30px 30px;
    border-radius: 8px;
    background-color: var(--bd-white);
    box-shadow: 0px 4px 80px rgba(0, 0, 0, 0.12);
}

.booking-form-input {
    input {
        background-color: var(--bd-white);
    }
}

.booking-form-input-title label {
    margin-bottom: 5px;
    text-transform: capitalize;
    font-size: 16px;
    text-align: left;
    display: block;
}

.booking-form-input-title label span {
    color: var(--bd-bg-danger);
    margin-inline-start: 3px;
}

.booking-card-tab {
    .nav-link {
        font-size: 18px;
        font-weight: 500;
        color: var(--bd-heading);
        padding: 0 15px 0 0;
    }

    .nav-item {
        display: block;
    }
}

.booking-card-tab .nav-pills .nav-link.active,
.booking-card-tab .nav-pills .show>.nav-link {
    color: var(--bd-primary);
    background-color: transparent;
}

.form-check {
    display: block;
    min-height: 0;
    padding-inline-start: 0;
    margin-bottom: 0;
}

.form-check-inline.style_two {
    &:not(:first-child) {
        margin-inline-start: 30px;
    }
}

.booking-card-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 24px;

    @media #{$xs} {
        grid-template-columns: repeat(1, 1fr);
        gap: 6px;
    }
}

.credit-card-logo {
    position: absolute;
    top: 30px;
    right: 15px;
    transform: translateY(-50%);
}

.credit-card-logo i {
    font-size: 40px;
    color: var(--bd-black);
}

.booking-sidebar-wrapper {
    background-color: var(--bd-white);
    box-shadow: 0px 4px 80px rgba(0, 0, 0, 0.12);
    padding: 30px 30px;
    border-radius: 8px;
    margin-left: 24px;

    @media #{$lg,$md,$sm,$xs} {
        margin-left: 0;
    }
}

.booking-sidebar-widget-thumb {
    img {
        border-radius: 8px;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}

.booking-item-date span {
    color: var(--bd-primary);
    margin-right: 2px;
}

.booking-sidebar-price-item {
    &:not(:last-child) {
        margin-bottom: 10px;
    }
}


.steps-title {
    display: none;

    &.step-active {
        display: block;
    }
}



.booking-model {
    .modal-header,
    .modal-body,
    .modal-footer {
        padding: 30px 30px;
    }
    .modal-header {
        border-bottom: 1px solid var(--bd-border-primary);
        padding-bottom: 15px;
        .btn-close {
            border: 1px solid var(--bd-border-secondary);
            border-radius: 60px;
            width: 26px;
            height: 26px;
        }
    }

    .modal-body-top {
        border-bottom: none;
    }

    .modal-footer {
        padding-top: 15px;
        border-top: 1px solid var(--bd-border-primary);
    }
}

.booking-sidebar-wrapper {
    .booking-model {
        .modal-header,
        .modal-body,
        .modal-footer {
            padding: 0px 0px;
        }
        .modal-header {
            border-bottom: 1px solid var(--bd-border-primary);
            padding-bottom: 15px;
            .btn-close {
                border: 1px solid var(--bd-border-secondary);
                border-radius: 60px;
                width: 26px;
                height: 26px;
            }
        }
    
        .modal-body-top {
            border-bottom: none;
        }
    
        .modal-footer {
            padding-top: 15px;
            margin-top: 15px;
            border-top: 1px solid var(--bd-border-primary);
        }
    }
}

.guest-number {
    width: 168px;
    position: relative;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    align-items: center;
    justify-content: space-between;

    .guest-number-plus,
    .guest-number-minus {
        width: 36px;
        height: 36px;
        justify-content: center;
        font-size: 16px;
        cursor: pointer;
        transition: all 0.3s 0s ease-out;
        display: inline-flex;
        text-align: center;
        justify-content: center;
        align-items: center;
        border: 1px solid var(--bd-border-secondary);
        border-radius: 60px;
        color: var(--bd-primary);
        position: absolute;
        top: 50%;
        left: 45px;
        transform: translateY(-50%);

        &:hover {
            background-color: var(--bd-primary);
            color: var(--bd-white);
            border-color: var(--bd-primary);
        }
    }

    .guest-number-plus {
        left: auto;
        right: 0px;
    }

    .guest-number-input[type="text"] {
        width: 36px;
        height: 36px;
        text-align: center;
        font-size: 20px;
        border: 1px solid transparent;
        background-color: transparent;
        padding: 0 0;
        line-height: 1.5;
        color: var(--bd-primary);
        border-radius: 60px;
        display: inline-block;
        text-align: center;
        position: absolute;
        left: 62%;
        top: 50%;
        transform: translate(-50%, -50%);
        font-weight: 600;
    }
}

.booking-infant-age {
    .nice-select {
        color: var(--bd-heading);
        background-color: transparent;
        border-radius: 4px;
        border: solid 1px var(--bd-border-secondary);
    }

    &.nice-select::after {
        color: var(--bd-heading) !important;
    }

    .option.selected.focus {
        background-color: var(--bd-flash-white);
    }

    &.nice-select .option.selected {
        font-weight: var(--bd-fw-regular);
        color: var(--bd-heading);
        font-size: 16px;
    }
}

.booking-modal-form-input {
    input[type="text"] {
        outline: none;
        background-color: transparent;
        height: 36px;
        width: 250px;
        font-size: 16px;
        border-radius: 4px;
        border: 1px solid var(--bd-border-secondary);
        padding: 10px 10px;
        color: var(--bd-black);
        cursor: pointer;
    }

    .form-control:focus {
        box-shadow: none;
        border-color: var(--bd-primary);
    }
}